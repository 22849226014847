import React, { Fragment, useEffect, useState } from "react";
import Header from "../../component/header";
import config from "../../config/config";
import moment from "moment";
import api from "../../api/api";
import { STORAGEKEY } from "../../constants";
import { Spinner } from "react-bootstrap";
import useSessionTimeout from "../../hooks/useSessionTimeout";

const MyOrders = () => {
  const [myOrders, setMyOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const sessionTimeout = useSessionTimeout();

  const handleGetMyOrders = async () => {
    setIsLoading(true);

    try {
      const { data } = await api.getWithParams("/GetTableQROrderDtls/01", {
        sessionid: localStorage.getItem(STORAGEKEY.SESSION_ID)
          ? localStorage.getItem(STORAGEKEY.SESSION_ID)
          : "",
        storename: localStorage.getItem(STORAGEKEY.STORE_NAME)
          ? localStorage.getItem(STORAGEKEY.STORE_NAME)
          : "",
        tableno: localStorage.getItem(STORAGEKEY.TABLE_NO)
          ? localStorage.getItem(STORAGEKEY.TABLE_NO)
          : "",
        trandate: moment().format("YYYY/MM/DD"),
      });

      const groups = [];

      data?.data?.[0]?.output?.forEach((record) => {
        const item = {};
        const saleNo = record?.sales_no;

        if (!item[saleNo]) {
          item[saleNo] = [];
        }

        record?.sales_dtls.forEach((order) => {
          item[saleNo].push(order);
        });

        groups.push(item);
      });

      const groupArrays = [];

      groups.forEach((record, index) => {
        let item = {};

        Object.keys(record).forEach((saleNo) => {
          item = {
            saleNo,
            date: groups[index][saleNo]?.[0]?.item_kds_ready_datetime,
            orders: groups[index][saleNo],
          };
        });

        groupArrays.push(item);
      });

      setIsLoading(false);
      setMyOrders(groupArrays);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleGetMyOrders();
  }, []);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="My Orders" />
          <div
            className="inner-div main-div position-absolute w-100"
            style={{ minHeight: "100vh", paddingTop: 80 }}>
            <div>
              {isLoading ? (
                <Spinner animation="border material-icons align-self-center position-absolute top-50" />
              ) : myOrders?.length === 0 ? (
                <div>
                  <img
                    src={`/assets/commonImages/${config?.empty}.png`}
                    alt="empty Screen"
                    className="mt-5 pt-5"
                  />
                  <div className="d-grid mt-5">
                    <label className="no-history-text mb-1">No My Orders</label>
                    <label className="no-history-desc">
                      You don't have any orders yet
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div className="d-flex ps-4">
                      <label className="last-order-text pe-3">
                        Your current orders
                      </label>
                    </div>
                    <div className="bg-white">
                      {myOrders?.map(
                        (orderData, index) =>
                          orderData?.orders?.length > 0 && (
                            <Fragment key={index}>
                              <h5 className="mb-1 order-item-header pt-2 pb-2 ps-3 pe-4">
                                {orderData?.saleNo}
                              </h5>
                              <div className="d-flex flex-column div-border ps-3 pe-4 my-order-item">
                                <h6
                                  className="mb-1"
                                  style={{
                                    width: "max-content",
                                  }}>
                                  {moment(orderData?.date).format(
                                    "DD MMM YYYY HH:mm A"
                                  )}
                                  )
                                </h6>
                                <div className="d-flex flex-column gap-1">
                                  {orderData?.orders?.map((record, idx) => (
                                    <div key={`order-item-${idx}`} className="">
                                      <div className="d-flex justify-content-between">
                                        <label className="order-title text-start d-block overflow-hidden text-nowrap order-text">
                                          {idx + 1}. {record?.item_name}
                                        </label>
                                        <label className="order-date text-start">
                                          x {record?.qty}
                                        </label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Fragment>
                          )
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyOrders;
