import React from "react";
import { Button } from "react-bootstrap";
import Icon from "../Icon/Icon";

const ButtonOption = ({
  checked,
  name,
  value,
  onClick,
  iconOptions,
  disabled,
}) => {
  return (
    <Button
      disabled={disabled}
      className={`btn-option-container ${
        checked ? "btn-option-container-active" : ""
      } ${disabled ? "btn-option-container-disabled" : ""}`}
      onClick={(event) => onClick(name, value, event)}>
      <Icon {...iconOptions} />
    </Button>
  );
};

export default ButtonOption;
