const retryTimeout = async (onCallback, retries = 3) => {
  try {
    return await onCallback?.();
  } catch (error) {
    if (retries > 0) {
      return await retryTimeout?.(onCallback, retries - 1);
    }

    throw new Error(error?.message || "Error with POS API");
  }
};

export default retryTimeout;
