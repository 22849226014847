import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

function MenuItems({
  menuDishes,
  placeOrder,
  onDetailOpen,
  supaProduct,
  supaProducts,
}) {
  const cartData = useSelector((state) => state.cart.cart);

  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`;

  const [imageURL, setImageURL] = useState(defaultImage);
  const [loading, setLoading] = useState(false);
  const [isDefaultImage, setIsDefaultImage] = useState(false);

  const cartItemData = cartData.filter(
    (item) => menuDishes.item_no === item.id
  );
  const cartItemDataQty = cartItemData?.reduce(
    (total, record) => (total += record?.qty),
    0
  );

  function insertLineBreak(category) {
    if (category.includes("(")) {
      return category.replace("(", "\n(");
    } else {
      return category;
    }
  }

  useEffect(() => {
    setLoading(true);

    if (menuDishes?.item_no) {
      const originalImageUrl = supaProduct?.image_url;

      checkIfImageExists(originalImageUrl, (exists) => {
        setIsDefaultImage(!exists);

        if (exists) {
          setImageURL(originalImageUrl);
        } else {
          setImageURL(defaultImage);
        }
      });

      setLoading(false);
    }
  }, [defaultImage, menuDishes.item_no, supaProduct?.image_url]);

  return (
    <>
      {/* <div id='menuitem' class="menuitem p-2 mb-2 rounded" style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)' }}> */}
      <div
        id="menuitem"
        className="menuitem mb-2  d-flex flex-column"
        style={{ boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)" }}>
        {/* <div id={menuDishes.category_code} className="break-parenthesis" style={{ color: "#f0f8ff00" }}>
          {insertLineBreak(menuDishes.category_code)}
        </div> */}
        {Object.keys(supaProducts).length > 0 && !loading && (
          <img
            // temp fix
            src={imageURL}
            className={`carousel-cls ${isDefaultImage ? "image-contain" : ""}`}
            alt=""
            onClick={() => onDetailOpen(menuDishes)}
          />
        )}
        {menuDishes.is_top_pick === true ? (
          <div className="position-absolute d-flex best-seller-tag ms-2">
            <span className="best-seller-text text-white">Top Pick!</span>
          </div>
        ) : (
          ""
        )}

        <div className="d-grid text-start mb-2 mx-2">
          <span className="menu-title mt-3 text-break text-uppercase">
            {insertLineBreak(menuDishes.item_desc)}
            {menuDishes.bal_qty > 0 ? (
              <span className="bal-qty-text mt-2">
                {/* {console.log(cartItemData[0])} */}(
                {menuDishes.bal_qty - (cartItemDataQty || 0)} left)
              </span>
            ) : (
              ""
            )}
          </span>
          <div className="d-flex flex-row align-items-end">
            <span className="menu-price-text mt-2 me-2">
              $
              {Number(
                menuDishes?.selling_uom_dtls?.[0]?.price_dtls?.[0]
                  ?.dine_in_price
              ).toFixed(2)}
            </span>
            {menuDishes?.dummy_price && (
              <span className="dummy-price-text mt-2">
                ${Number(menuDishes?.dummy_price).toFixed(2)}
              </span>
            )}
          </div>
        </div>
        {menuDishes.is_soldout === "Y" ||
        !menuDishes.is_soldout ||
        menuDishes.is_emenu_disable === "Y" ||
        !menuDishes.is_emenu_disable ||
        (menuDishes.is_avl_limit_check === "Y" && menuDishes.bal_qty === 0) ||
        !menuDishes.is_avl_limit_check ? (
          <button className="sold-out-btn mx-2 mb-2 mt-auto" disabled>
            SOLD OUT
          </button>
        ) : (
          <>
            {cartItemData &&
            cartItemData.length > 0 &&
            cartItemDataQty >= menuDishes.bal_qty &&
            menuDishes.bal_qty !== 0 ? (
              <button
                className="sold-out-btn mx-2 mb-2 mt-auto flex-end"
                disabled>
                ADD
              </button>
            ) : (
              <>
                <button
                  className="border-btn add-cart-btn d-flex justify-content-center align-items-center shaking-button mx-2 mb-2 mt-auto flex-end"
                  style={{ flexGrow: 0 }}
                  onClick={() => placeOrder(menuDishes)}>
                  ADD
                </button>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default MenuItems;
