import React, { useEffect, useState } from "react";
import checkIfImageExists from "../../utils/checkIfImageExists";

const RecommendationItems = ({
  sliderdata,
  index,
  placeOrder,
  onDetailOpen,
  supaProducts,
  onDispatchCartTax,
}) => {
  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`;

  const [imageUrl, setImageUrl] = useState();

  const findProduct = supaProducts?.[sliderdata?.item_no];
  const originalImageUrl = findProduct?.image_url;

  useEffect(() => {
    checkIfImageExists(originalImageUrl, (exists) => {
      if (exists) {
        setImageUrl(originalImageUrl);
      } else {
        setImageUrl(defaultImage);
      }
    });
  }, [originalImageUrl]);

  useEffect(() => {
    if (sliderdata) {
      onDispatchCartTax?.(sliderdata);
    }
  }, [sliderdata]);

  return (
    <div className="item relative" data-value={index}>
      {Object.keys(supaProducts)?.length > 0 && imageUrl && (
        <img
          src={imageUrl}
          className="popular-img"
          alt=""
          onClick={() => onDetailOpen(sliderdata)}
        />
      )}
      <div className="d-grid text-start">
        <label className="recom-text mt-1 text-uppercase text-truncate">
          {sliderdata?.item_desc}
        </label>
        <label className="recom-price">
          $
          {Number(
            sliderdata?.selling_uom_dtls?.[0]?.price_dtls?.[0]?.dine_in_price
          ).toFixed(2)}
        </label>
      </div>
      {sliderdata.is_soldout === "Y" ||
      sliderdata.is_emenu_disable === "Y" ||
      (sliderdata.is_avl_limit_check === "Y" && sliderdata.bal_qty === 0) ? (
        <button className="sold-out-btn mt-3" disabled>
          SOLD OUT
        </button>
      ) : (
        <button
          className="border-btn add-btn mt-3"
          type="submit"
          onClick={() => placeOrder(sliderdata)}>
          ADD
        </button>
      )}
    </div>
  );
};

export default RecommendationItems;
