import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { AiFillInfoCircle } from "react-icons/ai";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import Header from "../../component/header";
import config from "../../config/config";
import { useSelector } from "react-redux";
import moment from "moment";
const OrderHistory = () => {
  const navigate = useNavigate();
  const invoiceNo = useSelector((state) => state.cart.sales_no);
  const handleClick = (orderData) => {
    navigate("/order-details", {
      state: { orderData: orderData },
    });
  };

  const orderDetails = useSelector((state) => state.cart.order_details);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Order History" />
          <div
            className="inner-div main-div position-absolute w-100"
            style={{ maxHeight: "90vh" }}>
            <div>
              {orderDetails.length === 0 ? (
                <div>
                  <img
                    src={`/assets/commonImages/${config?.empty}.png`}
                    alt="empty Screen"
                    className="mt-5 pt-5"
                  />
                  <div className="d-grid mt-5">
                    <label className="no-history-text mb-1">
                      No Order History
                    </label>
                    <label className="no-history-desc">
                      You don't have any order history yet
                    </label>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div className="d-flex ps-4 pt-3 pb-3">
                      <label className="last-order-text pe-3">
                        Your last 10 orders
                      </label>
                      <AiFillInfoCircle
                        id="second-app-title"
                        className="info-icon-cls"
                      />
                      <ReactTooltip
                        anchorId="second-app-title"
                        place="bottom"
                        variant="info"
                        content="Only last 10 order will displayed"
                      />
                    </div>
                    <div className="bg-white">
                      {orderDetails.slice(0, 10).map((orderData, index) => {
                        return (
                          <div
                            className="pt-3 pb-3 ps-3 pe-4 div-border"
                            onClick={() => handleClick(orderData)}>
                            {orderData?.order_status === "complete" ? (
                              <IoCheckmarkCircle className="check-icon-color float-start mt-2" />
                            ) : orderData?.order_status === "cancel" ? (
                              <IoCloseCircle className="close-icon-color float-start mt-2" />
                            ) : (
                              <img
                                src={`/assets/commonImages/${config?.clock}.png`}
                                alt=""
                                className="float-start mt-2"
                              />
                            )}
                            <div className="ms-3">
                              <div className="d-flex justify-content-between ms-3 mb-2">
                                <label className="order-title text-start d-block overflow-hidden text-nowrap order-text">
                                  {orderData?.items?.[0]?.item.item_desc}
                                </label>
                                <label className="order-title black-text text-end">
                                  {"$ " +
                                    Number(orderData?.subTotal).toFixed(2)}
                                </label>
                              </div>
                              <div className="d-flex justify-content-between ms-3">
                                <label className="order-date text-start">
                                  {invoiceNo}
                                </label>
                                <label className="order-date text-end">
                                  {moment(orderData?.time).format(
                                    "DD MMM YYYY HH:mm A"
                                  )}
                                </label>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderHistory;
