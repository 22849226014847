import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "./styles.css";
import useAuthenticate from "../../hooks/useAuthenticate";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const { isAuthenticate, onForgotPassword } = useAuthenticate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Email is required"),
    }),
    onSubmit: async (values) => {
      const success = await onForgotPassword(values);

      if (success) {
        navigate("/login");
      }
    },
  });

  useEffect(() => {
    if (isAuthenticate) {
      navigate("/reward");
    }
  }, [isAuthenticate, navigate]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="title-bg position-absolute w-100 start-0 z-index-1">
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/logo/logo.png`}
              alt=""
              className="auth-logo-img pt-2"
            />
          </div>
          <div className="position-absolute w-100 d-flex flex-column justify-content-center h-100 pe-3 ps-3">
            <Form
              className="d-flex flex-column gap-3"
              onSubmit={formik.handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label className="d-flex font-family-class fw-normal font-14 line-height-21 text-white">
                  Email
                </Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="eg: example@example.com"
                  className="font-family-class fw-normal font-14 line-height-21"
                />
                <Form.Text>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger font-family-class fw-normal font-12 line-height-17 mt-2 text-start">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <div className="d-flex flex-column-reverse gap-2">
                <button
                  type="submit"
                  disabled={!formik.isValid}
                  className="continue-as-guest d-flex justify-content-center text-white align-items-center">
                  Submit
                </button>
              </div>
            </Form>
          </div>
          <img
            src={`/assets/${process.env.REACT_APP_MODE}/background/splash.png`}
            alt=""
            className="w-100 h-100"
          />
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordScreen;
