import React, { useEffect } from "react";
import useBrandConfig from "./useBrandConfig";
import { STORAGEKEY } from "../constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const useSessionTimeout = () => {
  const { config } = useBrandConfig();
  const navigate = useNavigate();

  const sessionTimeout = config?.userSessionTimeout
    ? config?.userSessionTimeout / 1000 / 60
    : 60; // in minutes
  const dateSessionTimeout = localStorage.getItem(STORAGEKEY.SESSION_TIMEOUT)
    ? new Date(localStorage.getItem(STORAGEKEY.SESSION_TIMEOUT))
    : undefined;

  useEffect(() => {
    if (dateSessionTimeout && sessionTimeout > 0) {
      const diffMinutes = moment(new Date()).diff(
        moment(dateSessionTimeout),
        "minutes"
      );

      if (diffMinutes > sessionTimeout) {
        // navigate("/qr-not-valid");
      }
    }
  }, [config]);

  return { config };
};

export default useSessionTimeout;
