"use client";

import React, { useCallback, useEffect, useState } from "react";
import Lottie from "lottie-react";
import useBrandConfig from "../../hooks/useBrandConfig";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setOpenLottieAddCart } from "../../redux/appSlice";

const LottieAnimationAddCart = () => {
  const dispatch = useDispatch();
  const { openLottieAddCart } = useSelector((state) => state.app);

  const [animationData, setAnimationData] = useState();
  const [countDown, setCountDown] = useState(3);

  const { config } = useBrandConfig();

  const handleClose = useCallback(() => {
    setCountDown(3);
    dispatch(setOpenLottieAddCart(false));
  }, [dispatch]);

  const handleGetRemoteAnimation = useCallback(async () => {
    try {
      const response = await axios.get(config?.lottieUrl);

      setAnimationData(response?.data);
    } catch (error) {
      console.log(error);
    }
  }, [config?.lottieUrl]);

  useEffect(() => {
    if (config?.lottieUrl) {
      handleGetRemoteAnimation();
    }
  }, [config?.lottieUrl, handleGetRemoteAnimation]);

  useEffect(() => {
    if (openLottieAddCart) {
      const interval = setInterval(() => {
        if (countDown === 0) {
          handleClose();
          return;
        }

        setCountDown(countDown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countDown, handleClose, openLottieAddCart, window.location.pathname]);

  // useEffect(() => {
  //   handleClose();
  // }, [window.location.pathname]);

  return (
    openLottieAddCart &&
    animationData && (
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "70%",
          zIndex: 2000,
          left: "15%",
        }}>
        <Lottie
          animationData={animationData}
          loop={3000}
          autoPlay={true}
          width={300}
          height={300}
        />
      </div>
    )
  );
};

export default LottieAnimationAddCart;
