import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, updateToCart } from "../../redux/cartSlice";
import { IoIosArrowBack } from "react-icons/io";
import useBrandConfig from "../../hooks/useBrandConfig";

import CustomizeDishItem from "./CustomizeDishItem";
import CustomizeDishMultiple from "./CustomizeDishMultiple";
import { STORAGEKEY } from "../../constants";
import { formatCents } from "../../utils/numberUtils";
import { getProducts } from "../../services/supabase/product";
import CustomizeDishImage from "./CustomizeDishImage";
import _, { isArray } from "lodash";
import usePromotion from "../../hooks/usePromotion";
import { setOpenConfetti, setOpenLottieAddCart } from "../../redux/appSlice";

function CustomizeDish() {
  const { state } = useLocation();
  const totalPrice = useRef(0);
  const dineOption = localStorage.getItem("selectiDineOption");
  const quantity = useRef(state.qty ? state.qty : 1);
  const [counter, setCounter] = useState(state.qty ? state.qty : 1);
  const [numChecked, setNumChecked] = useState(0);
  let count = 0;
  const navigate = useNavigate();
  const [formState, setFormState] = useState(true);
  let initialValue = {};
  let validation = {};
  const cartData = useSelector((state) => state.cart.cart);

  const [loading, setLoading] = useState(false);

  // const cartItemData = cartData.filter(
  //   (item) => state.item.item_no === item.id
  // );
  const isSafari =
    !navigator.userAgent.includes("Chrome") &&
    navigator.userAgent.includes("Safari");

  console.log("state", state);

  const { iconConfig, config } = useBrandConfig();
  const { handleConvertPromotionItem } = usePromotion();

  const [productsSupabase, setProductsSupabase] = useState([]);

  const stepperMenuIcon = iconConfig?.stepper_menu;
  const defaultIcon = iconConfig?.default_icon;

  for (let value of state.item?.itemmaster_menutype_grpdtls) {
    if (value.max_qty === 1) {
      const originalVal = state?.addOnValues
        ? state?.addOnValues.filter(
            (addOn) => addOn?.modifier_name === value?.modifier_name
          )?.[0]?.citem_name
        : (state?.item?.itemmaster_menutypedtls || [])?.filter(
            (customItems) => customItems?.modifier_name === value?.modifier_name
          )?.[0];

      if (originalVal?.is_default !== "N") {
        initialValue = {
          ...initialValue,
          [value.modifier_name]:
            state.addOnValues && state.addOnValues.length > 0
              ? state.addOnValues.filter(
                  (addOn) => addOn.modifier_name === value.modifier_name
                )?.[0]?.citem_name
              : (state.item?.itemmaster_menutypedtls || []).filter(
                  (customItems) =>
                    customItems.modifier_name === value.modifier_name
                )?.[0]?.citem_name,
        };
      }

      if (value?.is_optional === "N") {
        validation = {
          ...validation,
          [value.modifier_name]: Yup.string().required(
            `${value.modifier_name} is required`
          ),
        };
      }
    } else {
      const filterValues = (state.item?.itemmaster_menutypedtls || []).filter(
        (customItems) => customItems.modifier_name === value.modifier_name
      );

      let multipleItemsDefault = [];
      if (state.addOnValues) {
        multipleItemsDefault =
          state.addOnValues &&
          state.addOnValues.length > 0 &&
          state.addOnValues
            .filter((addOn) => addOn.modifier_name === value.modifier_name)
            .map((item) => item.citem_name);
      } else {
        filterValues.forEach((record) => {
          if (record?.is_default === "Y") {
            multipleItemsDefault.push(record.citem_name);
          }
        });
      }
      initialValue = {
        ...initialValue,
        [value.modifier_name]: multipleItemsDefault,
      };

      if (value?.is_optional !== "Y") {
        validation = {
          ...validation,
          // [value.modifier_name]: Yup.array()
          //   .max(
          //     value.max_qty,
          //     `Please select only ${value.max_qty} ${value.modifier_name}`
          //   )
          //   .min(
          //     value.max_qty,
          //     `Please select ${value.max_qty} ${value.modifier_name}`
          //   ),

          [value.modifier_name]: Yup.array().test(
            "maxQtyTest",
            `Choose max ${value?.is_optional === "Y" ? "(Optional)" : ""} ${
              value.max_qty
            } ${value.modifier_name}`,
            (valueItem) => validModifierCheck(value, valueItem)
          ),
        };
      }
    }
  }

  console.log("initialValue", initialValue);

  const validModifierCheck = (value, valueItem) => {
    let maxAvlQty = state.item?.itemmaster_menutype_grpdtls.filter(
      (item) => value.modifier_name === item.modifier_name
    )[0].max_qty;

    let selectedQty = 0;
    valueItem.forEach((item) => {
      selectedQty += (state.item?.itemmaster_menutypedtls || []).filter(
        (modItem) => modItem.citem_name === item
      )[0].qty;
    });

    return selectedQty > 0;
  };

  const selectedDineOption = localStorage.getItem(STORAGEKEY.DINING_MODE);

  const isDineIn = useMemo(
    () => selectedDineOption === "dinein",
    [selectedDineOption]
  );

  const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

  const dispatcher = useDispatch();
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: Yup.object(validation),
    onSubmit: (values) => {
      setLoading(true);

      let addOnValues = [];
      if (Object.keys(values) && Object.keys(values).length > 0) {
        for (const addOn of Object.keys(values)) {
          (state.item?.itemmaster_menutypedtls || [])
            .filter(
              (item) =>
                item.modifier_name === addOn &&
                (isArray(values[addOn])
                  ? values[addOn]?.includes(item.citem_name)
                  : values[addOn] === item.citem_name)
            )
            .map((addOnData) =>
              addOnValues.push({
                modifier_qty: isArray(values[addOn])
                  ? values[addOn].filter(
                      (addonName) => addonName === addOnData.citem_name
                    ).length
                  : 1,
                item_no: addOnData.citem_no,
                modifier_name: addOnData.modifier_name,
                citem_name: addOnData.citem_name,
                price_dtls: isDineIn
                  ? addOnData.price_dtls[0].dine_in_price
                  : addOnData.price_dtls[0].takeaway_price,
                sku_no: addOnData.citem_no.replace("PRD", "SKU"),
                uom: addOnData.uom,
              })
            );
        }
      }

      const itemInCart = cartData.find(
        (item) =>
          item.id === state.item.item_no &&
          item.addOnValues.length === addOnValues?.length &&
          _.isEqual(
            _.map(item.addOnValues, "item_no"),
            _.map(addOnValues, "item_no")
          )
      );

      if (state.qty) {
        dispatcher(
          updateToCart({
            uuid: state.uuid,
            id: state.item.item_no,
            addOnValues: addOnValues,
            item: state.item,
            qty: quantity.current,
            price: price.original,
            remarks: values.remarks,
          })
        );
      } else {
        dispatcher(
          addToCart(
            handleConvertPromotionItem(
              {
                uuid: itemInCart?.uuid,
                id: state.item.item_no,
                addOnValues: addOnValues,
                item: state.item,
                qty: quantity.current,
                price: price.original,
                remarks: values.remarks,
              },
              true
            )
          )
        );
      }

      if (config?.enableLottie) {
        dispatcher(setOpenLottieAddCart(true));
      }

      navigate(`/home?storename=${storeName}`);
    },
  });

  const incrementCounter = (modifierName, citemName) => {
    formik.setValues({
      ...formik.values,
      [modifierName]: [...formik.values[modifierName], citemName],
    });
  };

  const decrementCounter = (modifierName, citemName) => {
    const allElExceptTarget = formik.values[modifierName].filter(
      (it) => it !== citemName
    );

    const targetEl = formik.values[modifierName].filter(
      (it) => it === citemName
    );

    if (targetEl.length > 1) {
      targetEl.shift();
    }

    formik.setValues({
      ...formik.values,
      [modifierName]: [...allElExceptTarget, ...targetEl],
    });
  };

  const getQtyItem = (modifierName, citemName) =>
    formik.values[modifierName].filter((it) => it === citemName).length;

  const getQtyItemModifier = (modifierName) =>
    formik.values[modifierName].length;

  const getGroupQty = (modifierName, exceptCitemName) =>
    Array.isArray(formik.values[modifierName])
      ? formik.values[modifierName].filter((it) => it !== exceptCitemName)
          .length
      : 1;

  const selectedItems = useMemo(() => {
    const selectedItems = [];
    Object.keys(formik.values).map((item) => {
      if (typeof formik.values[item] === "string") {
        selectedItems.push(
          ...(state?.item?.itemmaster_menutypedtls || []).filter(
            (it) =>
              it.modifier_name === item && it.citem_name === formik.values[item]
          )
        );
      } else if (typeof formik.values[item] === "object") {
        formik.values[item].map((it) => {
          selectedItems.push(
            ...(state.item?.itemmaster_menutypedtls || [])?.filter(
              (record) =>
                record?.citem_name === it && record?.modifier_name === item
            )
          );
        });
      }
    });

    return selectedItems;
  }, [state, formik?.values]);

  const price = useMemo(() => {
    const price = selectedItems.reduce((total, item) => {
      return (
        total +
        item.price_dtls[0][isDineIn ? "dine_in_price" : "takeaway_price"] *
          item.qty
      );
    }, 0);

    return {
      original: price, // original price number
      formatted: formatCents(price), // Formatted  to display
    };
  }, [selectedItems]);

  const fetchSupabaseProducts = async () => {
    const resp = await getProducts(process.env.REACT_APP_MODE);
    if (resp.data.length !== 0) {
      // look for items where is_top_pick is true
      const topPicks = resp.data.filter((item) => item.is_top_pick);
      const topPicksIds = topPicks.map((item) => item.pos_item_no);
      setProductsSupabase(resp.data);
    }
  };

  useEffect(() => {
    fetchSupabaseProducts();
  }, []);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          {/* <Header title="Customize your order" /> */}
          <div className="inner-div main-div customize-dish-inner-div position-absolute w-100">
            <div
              className="d-flex align-items-center customize-header-arrow"
              style={{
                position: "fixed",
                top: 8,
                left: 20,
                zIndex: 9999,
                height: "fit-content",
              }}
              onClick={() => navigate(-1)}>
              <IoIosArrowBack size={32} />
            </div>
            <div className="customize-img-div pt-3 pe-3 ps-3 me-2 ms-2">
              {productsSupabase?.length > 0 && (
                <CustomizeDishImage
                  data={state.item}
                  productsSupabase={productsSupabase}
                />
              )}
            </div>
            <div className="customize-body">
              <Form className="customize-form" onSubmit={formik.handleSubmit}>
                <div className="mt-3 d-flex selection-div1">
                  <div className="set-width">
                    <div className="d-grid pt-3 ps-3">
                      <label className="text-start customize-item-name">
                        {state.item.item_name}
                      </label>
                    </div>

                    {state.item?.itemmaster_menutype_grpdtls &&
                      state.item?.itemmaster_menutype_grpdtls.length > 0 &&
                      [...state.item?.itemmaster_menutype_grpdtls]
                        .sort(
                          (a, b) =>
                            a.item_menutype_grpdtls - b.item_menutype_grpdtls
                        )
                        .map((custom, i) => {
                          const temperatureSingleOptionList = (
                            state.item?.itemmaster_menutypedtls || []
                          )
                            .filter(
                              (customItems) =>
                                customItems.modifier_name ===
                                custom.modifier_name
                            )
                            .filter((record) =>
                              record?.modifier_name === "TEMPERATURE" ||
                              record?.modifier_name === "HOT/ICE" ||
                              record?.modifier_name === "ICE LEVEL"
                                ? record?.citem_name?.includes("(H)") ||
                                  record?.citem_name?.includes("(I)")
                                  ? formik?.values?.[
                                      state.item
                                        ?.itemmaster_menutype_grpdtls?.[0]
                                        ?.modifier_name
                                    ]?.includes("H-")
                                    ? record?.citem_name?.includes("(H)")
                                    : record?.citem_name?.includes("(I)")
                                  : true
                                : true
                            );

                          const temperatureMultipleOptionList = (
                            state.item?.itemmaster_menutypedtls || []
                          )
                            .filter(
                              (customItems) =>
                                customItems.modifier_name ===
                                custom.modifier_name
                            )
                            .filter(
                              (record) => record?.is_emenu_disable !== "Y"
                            )
                            .filter((record) =>
                              record?.modifier_name === "TEMPERATURE" ||
                              record?.modifier_name === "HOT/ICE" ||
                              record?.modifier_name === "ICE LEVEL"
                                ? record?.citem_name?.includes("(H)") ||
                                  record?.citem_name?.includes("(I)")
                                  ? formik?.values?.[
                                      state.item
                                        ?.itemmaster_menutype_grpdtls?.[0]
                                        ?.modifier_name
                                    ]?.includes("H-")
                                    ? record?.citem_name?.includes("(H)")
                                    : record?.citem_name?.includes("(I)")
                                  : true
                                : true
                            );

                          return (
                            ((custom.max_qty === 1 &&
                              temperatureSingleOptionList?.length > 0) ||
                              (custom.max_qty !== 1 &&
                                temperatureMultipleOptionList?.length > 0)) && (
                              <>
                                {custom.max_qty === 1
                                  ? temperatureSingleOptionList?.length > 0 && (
                                      <>
                                        <div
                                          className="d-flex selection1  ps-3 pt-4"
                                          key={i}>
                                          <label className="customize-item-modi-name  text-uppercase">
                                            {custom.modifier_name}{" "}
                                            <span>*</span>
                                          </label>
                                          <label className="dot ms-3 me-3" />
                                          <label className="customize-item-modi-select text-uppercase">
                                            {custom?.is_optional === "Y"
                                              ? "Choose max 1 (Optional)"
                                              : "Please select 1"}
                                          </label>
                                        </div>
                                        <span className="font-14 d-flex ps-3 text-danger">
                                          {formik &&
                                            formik.errors &&
                                            Object.keys(formik.errors).length >
                                              0 &&
                                            formik.errors[custom.modifier_name]}
                                        </span>
                                        {temperatureSingleOptionList.map(
                                          (customData, index) => (
                                            <Fragment key={index}>
                                              <CustomizeDishItem
                                                stepperMenuIcon={
                                                  stepperMenuIcon
                                                }
                                                customData={customData}
                                                formik={formik}
                                                index={index}
                                                custom={custom}
                                                defaultIcon={defaultIcon}
                                                dineOption={dineOption}
                                                isDefaultSelect={
                                                  customData.is_default === "Y"
                                                }
                                              />
                                            </Fragment>
                                          )
                                        )}
                                      </>
                                    )
                                  : temperatureMultipleOptionList?.length >
                                      0 && (
                                      <>
                                        <div
                                          className="d-flex selection1  ps-3 pt-4"
                                          key={i}>
                                          <label className="customize-item-modi-name text-uppercase">
                                            {custom.modifier_name}{" "}
                                            <span>*</span>
                                          </label>
                                          <label className="dot ms-3 me-3" />
                                          <label className="customize-item-modi-select text-uppercase">
                                            {custom.max_qty > 0 &&
                                              `Choose max ${custom.max_qty} ${
                                                custom?.is_optional === "Y"
                                                  ? "(Optional)"
                                                  : ""
                                              }`}
                                          </label>
                                        </div>
                                        <span className="font-14 d-flex ps-3 text-danger">
                                          {formik &&
                                            formik.errors &&
                                            Object.keys(formik.errors).length >
                                              0 &&
                                            formik.errors[custom.modifier_name]}
                                        </span>
                                        {temperatureMultipleOptionList.map(
                                          (customData, index) => (
                                            <CustomizeDishMultiple
                                              index={index}
                                              stepperMenuIcon={stepperMenuIcon}
                                              customData={customData}
                                              formik={formik}
                                              setNumChecked={setNumChecked}
                                              decrementCounter={
                                                decrementCounter
                                              }
                                              getQtyItem={getQtyItem}
                                              getQtyItemModifier={
                                                getQtyItemModifier
                                              }
                                              getGroupQty={getGroupQty}
                                              incrementCounter={
                                                incrementCounter
                                              }
                                              custom={custom}
                                              numChecked={numChecked}
                                              defaultIcon={defaultIcon}
                                              dineOption={dineOption}
                                            />
                                          )
                                        )}
                                      </>
                                    )}
                              </>
                            )
                          );
                        })}

                    {config?.enableRemarks && (
                      <div>
                        <span className="mt-2 text-uppercase fw-bold">
                          Remarks / To Note
                        </span>
                        <Form.Group className="mb-3">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter remarks"
                            name="remarks"
                            onChange={formik.handleChange}
                            value={formik.values.remarks}
                            maxLength={100}
                            className="public-sans-font line-height-25 font-14 line-height-20 black-text"
                          />
                        </Form.Group>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`${
                    state.item?.itemmaster_menutype_grpdtls &&
                    state.item?.itemmaster_menutype_grpdtls.length > 0
                      ? "position-sticky"
                      : "position-fixed"
                  } set-width bottom-0 bg-white cart-button-div ps-3 pe-3 pt-1 pb-3`}>
                  {Object.values(formik.values).map((selectedItems) => {
                    const itemExist = (
                      state.item?.itemmaster_menutypedtls || []
                    ).filter(
                      (customItems) => customItems.citem_name === selectedItems
                    );
                    if (itemExist && itemExist.length > 0) {
                      count =
                        count + dineOption === "dinein"
                          ? itemExist[0].price_dtls[0]?.dine_in_price *
                            itemExist[0].qty
                          : itemExist[0].price_dtls[0]?.takeaway_price *
                            itemExist[0].qty;

                      totalPrice.current = count;
                    }
                  })}

                  <Button
                    className="fill-btn customize-btn mt-1 text-uppercase"
                    type="submit"
                    onClick={(values) => setFormState(values)}
                    disabled={!formik.isValid || loading}>
                    Add to cart - {price.formatted}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomizeDish;
