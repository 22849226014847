import React, { useEffect } from "react";
import useProfile from "../../hooks/useProfile";
import Header from "../../component/header";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import useAuthenticate from "../../hooks/useAuthenticate";
import { STORAGEKEY } from "../../constants";

const MyProfileScreen = () => {
  const { myProfile } = useProfile();
  const navigate = useNavigate();
  const { isAuthenticate } = useAuthenticate();

  const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

  useEffect(() => {
    if (!isAuthenticate) {
      navigate("/login");
    }
  }, [isAuthenticate, navigate]);

  return (
    <div className="App">
      <div className="qr-before-body w-100">
        <Header title="home" hideHeaderStatus />
        <div className="d-flex flex-column gap-4 my-profile-container position-relative">
          <div className="d-flex align-items-center my-profile-header">
            <BsArrowLeft className="text-white" onClick={() => navigate(-1)} />
            <div className="flex-grow-1 d-flex align-items-center justify-content-center">
              <span className="text-white">Profile</span>
            </div>
          </div>
          <div className="my-profile-info">
            <div className="my-profile-info-body">
              <span className="my-profile-info-body-welcome">Welcome</span>
              {myProfile?.last_name && myProfile?.first_name && (
                <h5>
                  {myProfile?.last_name} {myProfile?.first_name}
                </h5>
              )}
              <div className="my-profile-info-body-description">
                <div className="d-flex flex-column">
                  <span className="my-profile-info-body-description-label">
                    Card Number
                  </span>
                  <span className="my-profile-info-body-description-value">
                    {myProfile?.member_card_no}
                  </span>
                  <div className="d-flex flex-column">
                    <span className="my-profile-info-body-description-label">
                      Available Points
                    </span>
                    <span className="my-profile-info-body-description-value">
                      {myProfile?.balance_points}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="my-profile-auth-info">
            <div className="my-profile-auth-info-item">
              <div className="my-profile-auth-info-item-label">Email</div>
              <div className="my-profile-auth-info-item-value">
                {myProfile?.email}
              </div>
            </div>
            <div className="my-profile-auth-info-item">
              <div className="my-profile-auth-info-item-label">Mobile No.</div>
              <div className="my-profile-auth-info-item-value">
                {myProfile?.mobile_no}
              </div>
            </div>
          </div>
          <div className="my-profile-action">
            <button
              onClick={() => navigate("/reward")}
              className="continue-as-guest login-btn d-flex justify-content-center text-white align-items-center">
              Redeem Voucher
            </button>
            <button
              onClick={() => navigate(`/home?storename=${storeName}`)}
              className="continue-as-guest d-flex justify-content-center text-white align-items-center">
              Back to Menu
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfileScreen;
