import React from "react";

import "./styles.css";

const Loading = ({ className = "" }) => {
  return (
    <div className={className}>
      <span className="loader"></span>
    </div>
  );
};

export default Loading;
