import axios from "axios";

const axiosWithTimeout = (options, timeout = 5000) => {
  return Promise.race([
    axios(options),
    new Promise((_, reject) =>
      setTimeout(() => {
        return reject(
          new Error(
            "We are currently experiencing difficulty loading the application. Please scan the QR code and try again"
          )
        );
      }, timeout)
    ),
  ]);
};

export default axiosWithTimeout;
