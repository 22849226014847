import React, { Fragment, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import usePromotion from "../../hooks/usePromotion";
import {
  setFirstTimeOpenFreeItemWithLimitModal,
  setOpenConfetti,
  setOpenFreeItemWithLimitModal,
  setOpenLottieAddCart,
} from "../../redux/appSlice";
import useBrandConfig from "../../hooks/useBrandConfig";
import { addToCart } from "../../redux/cartSlice";

import FreeItemByValueItem from "./FreeItemWithLimitItem";

import "./styles.css";

const FreeItemWithLimitModal = () => {
  const dispatch = useDispatch();
  const { openFreeItemWithLimitModal } = useSelector((state) => state.app);

  const [data, setData] = useState([]);

  const { promotionConfig } = usePromotion();
  const { config } = useBrandConfig();

  const freeItemWithLimitConfig = promotionConfig?.find(
    (record) => record?.criteria_type === "FO"
  );
  const dataFreeItemList = freeItemWithLimitConfig?.creteria_item_dtls;

  const handleIncreaseItem = (item) => {
    if (data?.length > 0) {
      return;
    }

    const cloneData = [...data];
    const findItem = cloneData?.find(
      (record) => record?.item_no === item?.item_no
    );

    if (findItem) {
      const findIndexItem = cloneData?.find(
        (record) => record?.item_no === item?.item_no
      );

      findItem.qty = findItem.qty + 1;

      cloneData[findIndexItem] = findItem;

      setData([...cloneData]);
    } else {
      setData([
        ...data,
        {
          ...item,
          qty: 1,
        },
      ]);
    }
  };

  const handleDecreaseItem = (item) => {
    const cloneData = [...data];
    const findItem = cloneData?.find(
      (record) => record?.item_no === item?.item_no
    );

    if (findItem.qty > 1) {
      const findIndexItem = cloneData?.find(
        (record) => record?.item_no === item?.item_no
      );

      findItem.qty = findItem.qty - 1;

      cloneData[findIndexItem] = findItem;

      setData([...cloneData]);
    } else {
      setData(cloneData?.filter((record) => record?.item_no !== item?.item_no));
    }
  };

  const handleAddCart = () => {
    data.forEach((record) => {
      toast.success(`${record.item_name}  ADDED TO CART`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      dispatch(
        addToCart({
          id: record.item_no,
          addOnValues: [],
          item: {
            ...record,
            promo_name: freeItemWithLimitConfig?.promo_name,
            disc_type: freeItemWithLimitConfig?.criteria_disc_type,
            disc_value: freeItemWithLimitConfig?.criteria_disc_value,
          },
          qty: 1,
          price: 0,
          isFreeWithLimit: true,
        })
      );
      dispatch(setOpenFreeItemWithLimitModal(false));
      setData([]);

      if (config?.enableLottie) {
        dispatch(setOpenLottieAddCart(true));
      }
    });
  };

  return (
    <Modal
      show={openFreeItemWithLimitModal}
      onHide={() => {
        dispatch(setOpenFreeItemWithLimitModal(false));
        dispatch(setFirstTimeOpenFreeItemWithLimitModal(false));
      }}
      centered
      className="customize-content">
      <Modal.Header closeButton className="modal-border" closeVariant="white">
        <Modal.Title>
          <div className="d-grid">
            <label className="customize-header">Choose your free item</label>
            <label className="customize-header">
              Select up to{" "}
              {freeItemWithLimitConfig?.criteria_free_item_qty_limit}{" "}
              {freeItemWithLimitConfig?.criteria_free_item_qty_limit > 1
                ? "items"
                : "item"}
            </label>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="free-item-by-value-container">
          {dataFreeItemList?.map((record, index) => (
            <Fragment key={index}>
              <FreeItemByValueItem
                data={record}
                formData={data?.find(
                  (item) => record?.item_no === item?.item_no
                )}
                onIncrease={handleIncreaseItem}
                onDecrease={handleDecreaseItem}
                freeItemData={data}
              />
            </Fragment>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="fill-cancel-btn w-100 fill-green-btn cart-btn text-uppercase fw-bold"
          onClick={handleAddCart}>
          Add
          <BsPlus style={{ fontSize: "25px", color: "white" }} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FreeItemWithLimitModal;
