import { Image as ImageBT, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from "./css/menuDetail.module.css";
import { useEffect, useState } from "react";

const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
};

const MenuDetail = ({ show, onHide, data, placeOrder }) => {
  const cartData = useSelector((state) => state.cart.cart);
  const cartItemData = cartData.filter((item) => data.item_no === item.id);
  const addToCart = (data) => {
    placeOrder(data);
    onHide();
  };

  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`;

  const [imageUrl, setImageUrl] = useState(defaultImage);
  const [loading, setLoading] = useState(true);

  const originalImageUrl = data?.supaProduct?.image_url;

  useEffect(() => {
    setLoading(true);

    checkIfImageExists(originalImageUrl, (exists) => {
      if (exists) {
        setImageUrl(originalImageUrl);
      } else {
        setImageUrl(defaultImage);
      }
    });

    setLoading(false);
  }, [defaultImage, originalImageUrl]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered={true}
      dialogClassName={styles.bottomModal}>
      <Modal.Header closeButton>
        {/* <Modal.Title>{data.item_desc}</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        {!loading && (
          <ImageBT
            fluid
            src={imageUrl}
            style={{
              minHeight: 180,
              objectFit: "contain",
            }}
          />
        )}
        <p style={{ fontWeight: "600", marginTop: "24px" }}>{data.item_desc}</p>
        <p style={{ fontWeight: "800" }}>
          $
          {Number(
            data?.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price
          ).toFixed(2)}
        </p>
        {data.supabase_item_desc && (
          <p style={{ margin: 0 }}>{data.supabase_item_desc}</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {data.is_soldout === "Y" ||
        !data.is_soldout ||
        data.is_emenu_disable === "Y" ||
        !data.is_emenu_disable ||
        (data.is_avl_limit_check === "Y" && data.bal_qty === 0) ||
        !data.is_avl_limit_check ? (
          <button className="sold-out-btn mb-2 mt-4" disabled>
            SOLD OUT
          </button>
        ) : (
          <>
            {cartItemData &&
            cartItemData.length > 0 &&
            cartItemData[0].qty >= data.bal_qty &&
            data.bal_qty !== 0 ? (
              <button className="sold-out-btn mb-2 mt-auto flex-end" disabled>
                ADD
              </button>
            ) : (
              <button
                className="border-btn add-cart-btn d-flex justify-content-center align-items-center shaking-button mb-2 mt-auto flex-end"
                onClick={() => addToCart(data)}>
                ADD
              </button>
            )}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};
export default MenuDetail;
