import React, { useLayoutEffect, useState } from "react";
import api from "../api/api";
import useAuthenticate from "./useAuthenticate";
import { useDispatch, useSelector } from "react-redux";
import { setAvailablePoints, setFirstRender } from "../redux/authSlice";

const useProfile = (notAuthenticate) => {
  const dispatch = useDispatch();
  const { firstRender } = useSelector((state) => state.auth);

  const [myProfile, setMyProfile] = useState({});

  const { onLogout } = useAuthenticate();

  const handleGetMyProfile = async () => {
    try {
      const { data } = await api.getFromAuthURLWithToken("/api/v1/member/me");

      setMyProfile(data);

      if (!firstRender) {
        dispatch(setAvailablePoints(data?.balance_points));
        dispatch(setFirstRender(true));
      }
    } catch (error) {
      console.log(error);
      if (!notAuthenticate) {
        onLogout();
      }
    }
  };

  useLayoutEffect(() => {
    handleGetMyProfile();
  }, []);

  return {
    myProfile,
  };
};

export default useProfile;
