import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

import { useDispatch, useSelector } from "react-redux";

import { setOpenConfetti } from "../../redux/appSlice";

import "./confetti.css";

const ConfettiEffect = () => {
  const dispatch = useDispatch();
  const { openConfetti } = useSelector((state) => state.app);

  const [countDown, setCountDown] = useState(5);

  const onClose = () => {
    setCountDown(5);
    dispatch(setOpenConfetti(false));
  };

  useEffect(() => {
    if (openConfetti) {
      const interval = setInterval(() => {
        if (countDown === 0) {
          onClose();
          return;
        }

        setCountDown(countDown - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countDown, openConfetti]);

  return (
    openConfetti && (
      <div className="firework-container">
        <Confetti width={window.innerWidth} height={window.innerHeight} />
      </div>
    )
  );
};

export default ConfettiEffect;
