import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import useBrandConfig from "../../hooks/useBrandConfig";
import { useState } from "react";

export const ConfirmationModal = ({
  showModal,
  setShowModal,
  paymentpage,
  setDisableButton,
  disableButton,
}) => {
  const navigate = useNavigate();
  const { config } = useBrandConfig();
  const [loading, setLoading] = useState(false);

  const WordingPaymentOption = {
    Counter: {
      img: "/assets/commonImages/pay-by-cash.png",
      label: "Pay at Counter ",
      action: async () => {
        setLoading(true);
        const res = await paymentpage(false, "counter");
        setLoading(false);
        if (!res.success) {
          return;
        }
        navigate("/payment-options", {
          state: {
            pm: "Counter",
          },
        });
      },
    },
    Online: {
      img: "/assets/commonImages/pay-by-credit-card.png",
      label: "Pay by Card",
      action: async () => {
        setLoading(true);

        await paymentpage(true, "card");

        setLoading(false);
      },
    },
    QlubQR: {
      img: "/assets/commonImages/pay-by-cash.png",
      label: "Pay with QlubQR",
      action: async () => {
        setLoading(true);
        const res = await paymentpage(false, "qlubQR");
        setLoading(false);
        if (!res.success) {
          return;
        }
        navigate("/payment-options", {
          state: {
            pm: "QlubQR",
          },
        });
      },
    },
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setDisableButton(false);
      }}
      centered>
      <Modal.Header closeButton>
        <Modal.Title>Choose Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center align-items-center gap-3">
          {config?.paymentMode?.map((it) => (
            <div key={it} className="payment-mode-item">
              <div className="payment-mode-item-img">
                <img
                  src={WordingPaymentOption[it]?.img}
                  alt=""
                  width="100%"
                  onClick={!loading && WordingPaymentOption[it].action}
                />
              </div>
              <span>{WordingPaymentOption[it].label}</span>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};
