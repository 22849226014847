import React, { useCallback, useEffect, useState } from "react";
import Header from "../../component/header";
import { useNavigate } from "react-router-dom";
import useProfile from "../../hooks/useProfile";
import useAuthenticate from "../../hooks/useAuthenticate";
import {
  storeMenuButton,
  storeMenuItems,
  storeStockList,
} from "../../services/tableQRServices";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import _, { get } from "lodash";
import { getCategories } from "../../services/supabase/category";
import api from "../../api/api";
import { STORAGEKEY } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import { toast } from "react-toastify";
import getUpsellingItems from "../../utils/getUpsellingitems";
import { CustomizeModal } from "../category/CustomizeModal";
import UpsellingModal from "../../component/Upselling/UpsellingModal";
import { setAvailablePoints } from "../../redux/authSlice";
import {
  setPosFullItemList,
  setPosMenuButton,
  setPosStoreStockList,
} from "../../redux/posSlice";
import { setRedeemableList } from "../../redux/appSlice";

const RewardScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [showModalData, setShowModalData] = useState({});
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [showUpsellingModal, setShowUpsellingModal] = useState(false);
  const [upsellingItems, setUpsellingItems] = useState([]);

  const navigate = useNavigate();
  const { isAuthenticate } = useAuthenticate();
  const { myProfile } = useProfile();
  const dispatch = useDispatch();
  const { availablePoints } = useSelector((state) => state.auth);
  const { redeemableList } = useSelector((state) => state.app);
  const cartItems = useSelector((state) => state.cart.cart);

  const itemsListAndCategory = () => {
    setIsLoading(true);
    storeStockList()
      .then((res) => {
        const result = res?.data?.data[0]?.output;

        dispatch(setPosStoreStockList(result));

        const resultParse = JSON.parse(result);
        let resultItemsParsed = resultParse.filter(
          (category) => category.avl_type === "I"
        );
        const categoryNames = allCategories.map((item) => item.name);
        menuButtonFunction(categoryNames, resultItemsParsed, resultParse);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const menuButtonFunction = (
    categoryCodes,
    resultItemsParsed,
    resultParse
  ) => {
    storeMenuButton()
      .then(async (res) => {
        const categoryMenuButton = res?.data?.data[0]?.output;

        dispatch(setPosMenuButton(categoryMenuButton));

        let categoryMainMenu = categoryMenuButton.filter(
          (menuItem) => menuItem.category && menuItem.category.length > 0
        );
        let categoryMainMenuButton = [];
        for (const catItems of categoryMainMenu) {
          categoryMainMenuButton.push(...catItems.category);
        }
        if (categoryMainMenuButton && categoryMainMenuButton.length > 0) {
          const categoregoryFromApi = categoryMenuButton
            .filter((category) => category.items !== "")
            .map((category) => {
              return category.root_category_code;
            });
          categoryCodes = _.intersection(categoryCodes, categoregoryFromApi);

          let menuItemsArray = [];
          for (const category of categoryCodes) {
            const catItem = categoryMenuButton.filter(
              (catItems) => catItems.root_category_code === category
            );
            if (
              catItem &&
              catItem.length > 0 &&
              catItem[0].items &&
              catItem[0].items.length > 0
            ) {
              menuItemsArray.push(...catItem[0].items);
            }
          }
          let intersectCategory = _.intersectionBy(
            resultItemsParsed,
            menuItemsArray,
            (obj) => obj.item_category || obj.item_no
          );
          intersectCategory = Object.values(
            _.merge(
              _.keyBy(intersectCategory, "item_category"),
              _.keyBy(menuItemsArray, "item_no")
            )
          ).filter(
            (item) =>
              item.is_emenu_disable === "N" &&
              item.start_time <= moment().format("HH:mm:ss") &&
              item.end_time > moment().format("HH:mm:ss")
          );

          menuItemsFunction(intersectCategory, categoryCodes, resultParse);
        }
      })
      .catch(() => {});
  };

  const menuItemsFunction = (intersectCategory, categoryCodes, resultParse) => {
    storeMenuItems()
      .then((res) => {
        const result = res?.data?.data[0]?.output;

        dispatch(setPosFullItemList(result));

        const filterItemMaster = result.filter(
          (item) =>
            item.itemmaster_menutypedtls &&
            item.itemmaster_menutypedtls.length > 0
        );

        const stockedCheckedItem = [];
        if (filterItemMaster && filterItemMaster.length > 0) {
          for (const itemMaster of filterItemMaster) {
            let itemmaster_menutypedtls = [];
            for (const typeDtls of itemMaster.itemmaster_menutypedtls) {
              const modifierQty = resultParse.filter(
                (itemCategory) => itemCategory.item_no === typeDtls.citem_no
              );

              if (modifierQty && modifierQty.length > 0) {
                itemmaster_menutypedtls.push({
                  ...typeDtls,
                  is_soldout: modifierQty[0].is_soldout,
                  is_emenu_disable: modifierQty[0].is_emenu_disable,
                  is_avl_limit_check: modifierQty[0].is_avl_limit_check,
                  bal_qty: modifierQty[0].bal_qty,
                });
              } else {
                itemmaster_menutypedtls.push({ ...typeDtls });
              }
            }

            stockedCheckedItem.push({ ...itemMaster, itemmaster_menutypedtls });
          }
        }
        const filterNoItemMaster = result.filter(
          (item) =>
            !item.itemmaster_menutypedtls ||
            item.itemmaster_menutypedtls.length <= 0
        );

        let intersectItems = _.intersectionBy(
          intersectCategory,
          [...filterNoItemMaster, ...stockedCheckedItem],
          (obj) => obj.item_category || obj.item_no
        ).filter((item) => item.is_active);

        intersectItems = Object.values(
          _.merge(
            _.keyBy(intersectCategory, "item_category"),
            _.keyBy([...filterNoItemMaster, ...stockedCheckedItem], "item_no")
          )
        )
          .filter(
            (item) =>
              item.is_active && categoryCodes.includes(item.category_code)
          )
          .filter((item) => item.is_emenu_disable === "N");

        const sortAlphabetically = intersectItems.sort((a, b) =>
          a.item_desc.localeCompare(b.item_desc)
        );
        const sortByCategory = sortAlphabetically.sort(
          (a, b) =>
            categoryCodes.indexOf(a.category_code) -
            categoryCodes.indexOf(b.category_code)
        );

        setProductList(sortByCategory);
        setIsLoading(false);
      })

      .catch((err) => {
        setIsLoading(false);
      });
  };

  const fetchSupabaseCategories = async () => {
    setIsLoading(true);
    const resp = await getCategories(process.env.REACT_APP_MODE);
    setAllCategories(
      resp.data?.filter(
        (record) => record?.is_hidden !== true && record?.is_hidden !== "Y"
      )
    );
  };

  const handleGetRedeemable = async () => {
    try {
      const { data } = await api.postFromAuthURLWithTokenAndParams(
        "/api/v1/member/redeemable",
        {
          brand: process.env.REACT_APP_MODE.toUpperCase(),
          outlet: localStorage.getItem(STORAGEKEY.STORE_NAME)
            ? localStorage.getItem(STORAGEKEY.STORE_NAME)
            : "",
        }
      );

      dispatch(setRedeemableList(data));
    } catch (error) {
      console.log(error);
    }
  };

  const getMenuTypeGrouped = useCallback((data) => {
    return get(data, "itemmaster_menutype_grpdtls", [])
      .slice()
      .sort((a, b) => a.item_menutype_grpdtls - b.item_menutype_grpdtls)
      .map((menuTypeGroup, i) => menuTypeGroup);
  }, []);

  const placeOrder = async (dishDetail) => {
    if (
      dishDetail.itemmaster_menutype_grpdtls === "" ||
      getMenuTypeGrouped(dishDetail)
        ?.map((menuTypeGroup) =>
          dishDetail?.itemmaster_menutypedtls.filter(
            (customItems) =>
              customItems.modifier_name === menuTypeGroup.modifier_name
          )
        )
        ?.every((record) => record?.length === 1)
    ) {
      const itemInCart = cartItems.find(
        (item) =>
          item.id === dishDetail.item_no &&
          item.addOnValues.length === 0 &&
          _.isEqual(_.map(item.addOnValues, "item_no"), _.map([], "item_no"))
      );

      dispatch(
        addToCart({
          id: dishDetail.item_no,
          uuid: itemInCart?.uuid,
          addOnValues: [],
          item: {
            ...dishDetail,
            disc_type: "P",
            disc_name: dishDetail?.reward_name,
            disc_value: 100,
            disc_amt:
              dishDetail.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price,
            sub_total: 0,
            isFree: true,
          },
          qty: 1,
          price: 0,
        })
      );
      dispatch(
        setAvailablePoints(
          availablePoints - dishDetail?.reward_type_attributes?.points
        )
      );
      toast.success(`${dishDetail.item_name}  ADDED TO CART`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
        dishDetail.category_code,
        dishDetail.item_category
      );
      if (hasUpsellingItems) {
        setUpsellingItems(upsellItems);
        setShowUpsellingModal(true);
      }
    } else {
      const customDetails = {
        item: dishDetail,
      };
      setShowModalData(customDetails);
      setShowCustomizeModal(true);
    }
  };

  const handleCustomModalSuccessSubmit = async (submittedItem) => {
    const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
      submittedItem.category_code,
      submittedItem.item_category
    );
    if (hasUpsellingItems) {
      setUpsellingItems(upsellItems);
      setShowUpsellingModal(true);
    }
  };

  useEffect(() => {
    if (!isAuthenticate) {
      navigate("/login");
    }
  }, [isAuthenticate, navigate]);

  useEffect(() => {
    fetchSupabaseCategories();
  }, []);

  useEffect(() => {
    if (allCategories?.length !== 0) {
      itemsListAndCategory();
      handleGetRedeemable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories]);

  const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="home" hideHeaderStatus />
          <div className="d-flex flex-column gap-4 reward-container">
            <div className="reward-point">
              <span className="reward-point-text">Available points</span>
              <span className="text-white">{availablePoints}</span>
            </div>
            <div className="reward-body">
              <button
                onClick={() => navigate(`/home?storename=${storeName}`)}
                className="continue-as-guest d-flex justify-content-center text-white align-items-center">
                Go To Menu
              </button>
              {isLoading ? (
                <Spinner animation="border material-icons align-self-center position-absolute top-50" />
              ) : !productList?.length ? (
                <span className="reward-not-found">Rewards not found</span>
              ) : (
                <div className="d-flex flex-column gap-2">
                  {redeemableList
                    ?.filter(
                      (record) =>
                        record?.reward_type === "menu" &&
                        productList?.find(
                          (item) =>
                            item?.item_no ===
                            record?.reward_type_attributes?.sku
                        )
                    )
                    ?.map((record) => {
                      const findProduct = productList?.find(
                        (item) =>
                          item?.item_no === record?.reward_type_attributes?.sku
                      );

                      return {
                        ...findProduct,
                        ...record,
                      };
                    })
                    ?.map((record) => (
                      <div className="reward-item">
                        <img
                          src={`https://imagedelivery.net/0btclAJWxGAPfkBxQs1olg/${process.env.REACT_APP_MODE}/${process.env.REACT_APP_MODE}_${record?.item_no}.jpg/public`}
                          className="reward-item-image"
                          alt=""
                        />
                        <div className="reward-item-body">
                          <h5>{record?.reward_name}</h5>
                          <span>{record?.reward_type_attributes?.points}</span>
                          <button
                            className="continue-as-guest d-flex justify-content-center text-white align-items-center"
                            onClick={() => placeOrder(record)}
                            disabled={
                              availablePoints <
                              record?.reward_type_attributes?.points
                            }>
                            Add
                          </button>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showCustomizeModal ? (
        <CustomizeModal
          showModal={showCustomizeModal}
          setShowModal={setShowCustomizeModal}
          showModalData={showModalData}
          onSuccessSubmit={handleCustomModalSuccessSubmit}
        />
      ) : null}
      {showUpsellingModal ? (
        <UpsellingModal
          show={showUpsellingModal}
          handleClose={() => setShowUpsellingModal(false)}
          recommendationItems={upsellingItems}
        />
      ) : null}
    </>
  );
};

export default RewardScreen;
