import React, { useEffect, useState } from "react";
import useBrandConfig from "../../hooks/useBrandConfig";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../component/Loading/Loading";
import { Button } from "react-bootstrap";

const defaultMessage =
  "Thank you for your order. Please wait while you are being redirected for payment";

const PaymentCallback = () => {
  const [showRetryPayment, setShowRetryPayment] = useState(false);
  const [message, setMessage] = useState(defaultMessage);

  const { config } = useBrandConfig();
  const { state } = useLocation();
  const navigate = useNavigate();

  const handleRetryPayment = async () => {
    setShowRetryPayment(false);
    window.location.href = state?.redirectLink;
    setMessage(defaultMessage);
  };

  useEffect(() => {
    if (navigator.onLine) {
      if (state?.redirectLink) {
        if (!showRetryPayment) {
          window.location.href = state?.redirectLink;

          setTimeout(() => {
            setShowRetryPayment(true);
            setMessage(
              "Redirecting to payment gateway is unsuccessful. Please retry"
            );
          }, 10000);
        }
      } else {
        navigate("/qr-not-valid");
      }
    } else {
      setMessage(
        "Redirecting to payment gateway is unsuccessful. Please retry."
      );
    }
  }, [state?.redirectLink, showRetryPayment]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="inner-div main-div position-absolute w-100">
            <div className="pt-5 mt-4">
              <img
                src={
                  config?.thank_you_banner ||
                  `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`
                }
                alt=""
                className="mt-5"
                width={300}
                height={300}
              />
            </div>
            <div className="d-grid p-3">
              <label className="thank-text mt-4 text-center">{message}</label>
              {!showRetryPayment && (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{
                    marginTop: 80,
                  }}>
                  <Loading />
                </div>
              )}
            </div>
            {showRetryPayment && (
              <Button
                className="thank-btn mt-4 me-4 ms-4"
                type="submit"
                onClick={handleRetryPayment}>
                Retry Payment
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCallback;
