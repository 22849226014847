import Header from "../../component/header";
import { useEffect } from "react";

function TOS() {
  // hide div with class header-status
  useEffect(() => {
    document
      .querySelector(".header-status")
      .style.setProperty("display", "none", "important");
    document
      .querySelector(".header")
      .style.setProperty("position", "relative", "important");
  }, []);
  return (
    <div className="App">
      <div className="qr-before-body w-100">
        <Header title="Terms Of Use" />

        <div className="inner-div main-div w-100">
          <div className=" w-100">
            <div className="d-flex ps-4 pb-2 pe-3 pt-3 bg-white">
              <div className="container font-12 text-start">
                <p className="text-start">
                  These Terms of Use, including all other terms, policies,
                  notices, guidelines, and forms referenced herein or available
                  on the QR link or by hyperlink, collectively referred to as
                  the "Terms," govern the use of the QR link and mobile
                  applications provided by Solution Details Pte. Ltd. (referred
                  to as "Solution Details," "we," or "us"). The Services
                  provided by us include both the QR link and the services
                  offered by Solution Details - Evolut POS, which are accessible
                  via the QR link. Any additional features added to the Services
                  will also be subject to these Terms. These Terms regulate the
                  use of Services provided by Solution Details - Evolut POS.
                </p>

                <p className="text-start">
                  Please carefully read these Terms before accessing and using
                  the QR link. By doing so, you agree that you have read,
                  understood, and accepted the Terms and give your consent to
                  the processing of your personal data in accordance with the
                  Privacy Policy. If you disagree with the Terms, please refrain
                  from accessing or using the QR link or the Services.
                </p>

                <p className="text-start">
                  The QR link is accessible to natural persons who have attained
                  the age of 18 years and corporate legal entities. Where
                  applicable, these Terms shall be subject to country-specific
                  provisions as set out herein.
                </p>

                <p className="text-start">
                  Users who are below 18 years of age must obtain consent from
                  their parent(s) or legal guardian(s) who, by accepting these
                  Terms, agree to assume responsibility for your actions and any
                  charges associated with your use of the QR link and/or
                  purchase of Goods. If you do not have consent from your
                  parent(s) or legal guardian(s), you must immediately
                  discontinue using/accessing the QR link.
                </p>

                <p className="text-start">
                  Solution Details - Evolut POS reserves the right to modify or
                  change these Terms (including any additional terms and
                  conditions or policies published on the QR link and
                  incorporated into these Terms) at any time. It is your
                  responsibility to regularly review the Terms, and we strongly
                  recommend doing so. By continuing to use the QR link following
                  the posting of amended Terms, you will be deemed to have
                  accepted the amended Terms, whether or not you have reviewed
                  them.
                </p>

                <p className="text-start">
                  All photos and images of Goods displayed on the QR link are
                  for illustrative purposes only and may differ from the actual
                  Goods.
                </p>

                <p className="text-start">
                  1. Use of the QR link 1.1. To use the QR link and place an
                  Order, you may be required to provide us with your mobile
                  number or email address depending on the login method you
                  choose. Alternatively, you may choose to login as a guest
                  without providing your personal information by selecting the
                  "Continue As Guest" login method. In certain instances, the QR
                  link may allow for single sign-on with your Facebook or Google
                  account, subject to your preference as a Customer. You are
                  solely responsible for all Orders placed through the QR link,
                  except in cases of fraud or abuse that are not attributable to
                  you. 1.2. Solution Details - Evolut POS shall not be
                  responsible for any issues arising from Orders resulting from
                  incomplete, inaccurate, or missing information provided by
                  you. You are obligated to provide complete, accurate, and
                  truthful information, including your delivery address and
                  contact information, for the proper processing of the Order.
                  1.3. If you wish to delete personal data collected by Solution
                  Details - Evolut POS, please refer to our privacy policy and
                  send us an email request. We reserve the right to restrict,
                  suspend, or terminate your use of the QR link if we have
                  reasonable grounds to suspect or discover that you have
                  engaged in any activity or conduct that violates these Terms
                  or constitutes abuse of the QR link, in our sole discretion
                </p>

                <p className="text-start">
                  2. Restrictions 2.1 Prohibited Activities on the QR Link. The
                  QR Link provided by Solution Details - Evolut POS shall not be
                  used for any illegal or prohibited activity, and Solution
                  Details reserves the right to investigate and take legal
                  action against any user who engages in any prohibited
                  activity. Prohibited activities include, but are not limited
                  to, the following: 2.1.1. Using the QR link for any purpose
                  that violates any applicable local laws or regulations,
                  including any fraudulent or deceptive purpose. 2.1.2. Posting
                  content that infringes any intellectual property rights, trade
                  secret rights, publicity rights, privacy rights, or any other
                  rights of any party. 2.1.3. Posting content that is unlawful,
                  obscene, defamatory, threatening, harassing, abusive,
                  slanderous, hateful, or embarrassing to any person or entity,
                  as determined by Solution Details in its sole discretion or
                  according to local community standards. 2.1.4. Posting content
                  that constitutes cyber-bullying, as determined by Solution
                  Details in its sole discretion. 2.1.5. Posting content that
                  depicts any dangerous, life-threatening, or otherwise risky
                  behaviour. 2.1.6. Posting telephone numbers, street addresses,
                  or last names of any person. 2.1.7. Posting URLs to external
                  websites or any form of HTML or programming code. 2.1.8.
                  Posting any content that may be considered spam, as determined
                  by Solution Details in its sole discretion. 2.1.9.
                  Impersonating another person while posting content using the
                  QR link. 2.1.10. Harvesting or collecting information about
                  others, including email addresses, without their consent.
                  2.1.11. Allowing any other person or entity to use your
                  identification for posting or viewing comments. 2.1.12.
                  Harassing, threatening, stalking, or abusing any person on the
                  QR link. 2.1.13. Engaging in any conduct that restricts or
                  inhibits any other person from using or enjoying the QR link
                  or exposing Solution Details, its customers, suppliers, or any
                  other parties to any liability or detriment of any type, in
                  the sole discretion of Solution Details. 2.1.14. Encouraging
                  other people to engage in any prohibited activities as
                  described herein. 2.2 Solution Details - Evolut POS Rights and
                  Obligations. Solution Details - Evolut POS reserves the right,
                  but is not obligated, to take any or all of the following
                  actions: 2.2.1. Investigate any allegation of a violation of
                  these Terms.
                </p>

                <p className="text-start">
                  3. Intellectual Property The following is the legal language
                  for the Terms of Use of Solution Details - Evolut POS's
                  software: 3.1 Ownership and Use of Trademarks All trademarks,
                  service marks, logos, and images (collectively referred to as
                  "Trademarks") displayed on the QR link or in Solution Details
                  - Evolut POS's marketing materials, whether registered or
                  unregistered, are the intellectual property of Solution
                  Details - Evolut POS and/or third parties who have authorized
                  Solution Details - Evolut POS to use them. You may not use,
                  copy, reproduce, republish, upload, post, transmit,
                  distribute, or modify these Trademarks in any way without
                  obtaining our prior express written consent. Any use of the
                  Trademarks on any unauthorized website or platform is strictly
                  prohibited. Solution Details - Evolut POS reserves the right
                  to enforce its intellectual property rights to the fullest
                  extent of the law, including criminal prosecution. We do not
                  warrant or represent that your use of any materials displayed
                  on the QR link will not infringe upon the rights of third
                  parties who are not owned by or affiliated with Solution
                  Details - Evolut POS. You assume all risk associated with the
                  use of these materials.
                </p>
                <p className="text-start">
                  4. Restrictions on Goods 4.1. Certain Goods available on the
                  QR link may be Restricted Goods subject to purchase
                  restrictions as per applicable laws in the country where such
                  purchases are made. Such restrictions include, but are not
                  limited to, minimum age requirements for alcohol/alcoholic
                  products and other goods that we may refuse to serve or
                  deliver to you based on the relevant statutory requirements
                  currently in force. By placing an order for Restricted Goods,
                  you represent and warrant that you meet the legal requirements
                  for purchasing such Restricted Goods. 4.2. Alcohol/Alcoholic
                  Products ("Alcohol") To purchase Alcohol, you must be of the
                  statutory legal age. Solution Details - Evolut POS, the
                  Customer, and their delivery riders, as applicable, reserve
                  the right in their sole discretion to: 4.2.1. request valid
                  proof of age (such as an ID card) from any individual before
                  serving or delivering Alcohol; 4.2.2. refuse to serve or
                  deliver Alcohol if you are unable to provide valid proof of
                  age; and/or 4.2.3. refuse to serve or deliver Alcohol to any
                  individual for any reason.
                </p>
                <p className="text-start">
                  5. Orders 5.1. Upon placing an Order through the QR link,
                  Solution Details - Evolut POS will confirm the Order by
                  sending a confirmation email that includes the Order receipt.
                  The Order may include applicable taxes, such as GST, and
                  delivery fees. 5.2. Some Customers may require a minimum Order
                  value (MOV) before delivering the Order. If the Order fails to
                  meet the MOV, you may be given the option to pay the
                  difference or add more Goods to the Order. 5.3. Solution
                  Details - Evolut POS and the Customer will reasonably attempt
                  to comply with any special instructions you provide for an
                  Order. However, if it is not feasible, possible, or
                  commercially reasonable to do so, Solution Details - Evolut
                  POS and/or the Customer reserve the right to prepare the Order
                  in accordance with standard operating procedures. Neither
                  Solution Details - Evolut POS nor the Customer shall be
                  responsible for replacing or refunding an Order that does not
                  conform to your special instructions. 5.4. Solution Details -
                  Evolut POS is not obligated to provide information on
                  allergens or ingredients on the QR link. Unless required by
                  applicable laws, Solution Details - Evolut POS does not
                  guarantee that the Goods sold by Customers are free of
                  allergens. If you have allergies, allergic reactions, or
                  dietary restrictions, please contact the Customer before
                  placing an Order through the QR link. 5.5. Please note that
                  the Customer may provide additional terms and conditions that
                  apply to your Order. 5.6. To use the delivery services, you
                  must provide the delivery address to the QR link to display
                  the available Customers in your delivery area before placing
                  the Order. 5.7. To complete an Order, follow the on-screen
                  instructions after clicking “Checkout.” You may need to
                  provide additional details for the Order to be completed.
                  Please review and confirm that all the information you
                  provide, including the amounts, delivery details, personal
                  details, payment information, and voucher codes (if
                  applicable), is accurate and complete before clicking “PLACE
                  ORDER” or “CONFIRM ORDER.” An Order is successfully placed
                  when you receive an email confirmation with the Order receipt.
                  5.8. By placing an Order through the QR link, you agree to the
                  relevant cancellation policy of the Customer and any
                  additional terms and conditions that may apply to you. To
                  cancel an Order after it has been placed, please contact the
                  Customer directly. The Customer may cancel an Order at any
                  time, such as when they are too busy or the ordered Goods are
                  unavailable. 5.9. By accepting the Goods provided by the
                  Customer on the QR link, you agree that the Customer’s refund
                  policy may apply to you. Please carefully review a Customer’s
                  terms and conditions (if any) for any conditions regarding
                  cancellations and refunds before placing an Order through the
                  QR link. If you request a refund (Refund Request), the
                  Customer will determine whether to approve the Refund Request
                  in accordance with their cancellation and refund policy. We
                  have no control over any Customer’s cancellation and refund
                  policy and are not responsible for any consequences resulting
                  from a cancellation or refund request. If there is a dispute
                  concerning the Customer’s cancellation or refund policy, we
                  may intervene and assist in resolving the dispute upon
                  notification by you or the Customer. We may implement a
                  cancellation and refund policy at our discretion, and by
                  continuing to use the QR link after receiving notice of such a
                  policy, you agree to be bound by it. If there is any
                  inconsistency between our policy and a Provider’s policy, our
                  policy will prevail. We may require additional information
                  before processing any refunds. 5.10. Solution Details - Evolut
                  POS reserves the right to cancel any Order and/or suspend,
                  deactivate, or terminate your use of the QR link at our
                  discretion if we reasonably suspect or detect fraudulent
                  behavior or activity associated with your Order.
                </p>
                <p className="text-start">
                  6. Prices and Payments 6.1. Prices and additional charges
                  (including but not limited to delivery, small order, or
                  service fees) indicated on the QR link are as at the time of
                  each Order and may be subject to change. 6.2. A breakdown of
                  the prices and additional charges (including but not limited
                  to delivery, small order or service fees) are displayed before
                  checkout. When you place an Order, you agree to all amounts,
                  additional charges and the final ‘Total’ amount which is
                  displayed to you. 6.3. You can choose to pay for an Order
                  using any of the different payment methods offered on the QR
                  link including: 6.3.1. Our payment partners: Visa, Mastercard,
                  American Express, Google Pay, PayPal, Apple Pay. 6.4. Payment
                  Methods Solution Details - Evolut POS reserves the right to
                  offer additional payment methods and/or remove existing
                  payment methods at any time in its sole discretion. If you
                  choose to pay using an online payment method, the payment
                  shall be processed by our third-party payment service
                  provider(s). With your consent, your credit card / payment
                  information will be stored with our third-party payment
                  service provider(s) for future Orders. Solution Details -
                  Evolut POS does not store your credit card or payment
                  information. 6.5. You must ensure that you have sufficient
                  funds on your credit and debit card to fulfil payment of an
                  Order.
                </p>
                <p className="text-start">
                  7. Representations, Warranties and Limitation of Liabilities
                  7.1. Representations and Warranties By accessing and using the
                  QR link, you acknowledge and agree that all content available
                  on the QR link is provided "as is" and "as available" without
                  any warranties, representations, or guarantees of any kind,
                  express or implied. You understand and agree that your use of
                  or reliance upon the QR link and any content, goods, products,
                  or services accessed or obtained thereby is at your sole risk
                  and discretion. You further acknowledge and agree that any
                  photos and images of the Goods displayed on the QR link are
                  for illustrative purposes only and may differ from the actual
                  Goods. While Solution Details - Evolut POS makes reasonable
                  efforts to ensure that the QR link and the Services we offer
                  are available at all times, we do not warrant or represent
                  that the QR link shall be provided in a manner that is secure,
                  timely, uninterrupted, error-free, free of technical
                  difficulties, defects, or viruses. You acknowledge and agree
                  that temporary interruptions of the QR link may occur due to
                  scheduled or regular system maintenance work, downtimes
                  attributable to internet or electronic communications, or
                  events of force majeure. 7.2. Limitation of Liability To the
                  extent permitted by applicable law, Solution Details - Evolut
                  POS (which includes its employees, directors, agents,
                  representatives, affiliates, and parent company) shall not be
                  liable for any loss or damage (whether arising in contract,
                  negligence, or otherwise) that you or any third party may
                  incur in connection with your use of the QR link, our
                  Services, or any website linked to our QR link, or any content
                  or material posted on it (if any). You acknowledge and agree
                  that your exclusive remedy with respect to your use of the QR
                  link is to discontinue your use of the QR link. You further
                  acknowledge and agree that the Solution Details - Evolut POS
                  entities, their agents, representatives, and service providers
                  shall not be liable for any indirect, special, incidental,
                  consequential, or exemplary damages arising from your use of
                  the QR link or for any other claim related in any way to your
                  use of the QR link. These exclusions for indirect, special,
                  consequential, and exemplary damages include, without
                  limitation, damages for lost profits, lost data, loss of
                  goodwill, work stoppage, computer failure, or malfunction, or
                  any other commercial damages or losses, even if the Solution
                  Details - Evolut POS entities, their agents, representatives,
                  and service providers have been advised of the possibility
                  thereof and regardless of the legal or equitable theory upon
                  which the claim is based. Because some states or jurisdictions
                  do not allow the exclusion or limitation of liability for
                  consequential or incidental damages, in such states or
                  jurisdictions, Solution Details - Evolut POS, the Solution
                  Details - Evolut POS entities, its agents, representatives,
                  and service providers' liability shall be limited to the
                  extent permitted by law. 7.3. Customer's Representations You
                  acknowledge and agree that Solution Details - Evolut POS shall
                  not be liable for any actions or omissions of the Customer or
                  you in regard to the provision of the Goods. Solution Details
                  - Evolut POS does not assume any liability for the quantity,
                  quality, condition, or other representations of the Goods
                  and/or services provided by Customers, or guarantee the
                  accuracy or completeness of the information (including menu
                  information, photographs, and images of the Goods) displayed
                  on the Customer's listing/offering on the QR link. You further
                  acknowledge and agree that nothing in these Terms shall
                  exclude Customer's liability for death or personal injury
                  arising from Customer's gross negligence or wilful misconduct.
                </p>
                <p className="text-start">
                  8. Customer Liability Customers are solely responsible for the
                  preparation, condition and quality of Goods sold to you.
                </p>

                <p className="text-start">
                  9. Personal Data (Personal Information) Protection You agree
                  and consent to Solution Details - Evolut POS and any of its
                  affiliate companies collecting, using, processing and
                  disclosing your Personal Data in accordance with these Terms
                  and as further described in our Privacy Policy. Our Privacy
                  Policy is incorporated by reference herein and accessible here
                  (link for privacy policy) or via the links on our QR link.
                </p>
                <p className="text-start">
                  10. Indemnity By using the QR link, you agree to compensate us
                  for all losses, damages, liabilities, claims, and expenses
                  (including reasonable legal costs) we reasonably incur due to
                  your use of the QR link, your violation of the Terms, or any
                  infringement of the rights of third parties, except for
                  losses, damages, liabilities, claims, or expenses caused or
                  contributed to directly by us. We reserve the right to take
                  control of the defense of any matter that requires
                  indemnification by you, at our own expense. In such a case,
                  you agree to cooperate with us in defending such claims.
                </p>
                <p className="text-start">
                  11. Termination Solution Details reserves the right to
                  terminate, suspend, or delete your access to or use of the QR
                  link, as well as any services provided to you in connection
                  with an Order, for any reason. Such reasons may include
                  Solution Details' determination, in its sole discretion, that
                  your use is unacceptable or that you have breached any of the
                  Terms, or if Solution Details suspects or discovers that you
                  have engaged in any activity or conduct that violates the
                  Terms. Solution Details may, but is not obligated to, provide
                  you with a warning before terminating your use of the QR link.
                  Furthermore, if Solution Details assumes the exclusive control
                  of a matter that would otherwise be subject to indemnification
                  by you, you agree to cooperate with its defence of such a
                  claim.
                </p>

                <p className="text-start">
                  12. Amendments Solution Details - Evolut POS reserves the
                  right to modify these Terms at any time at its sole
                  discretion, and the updated Terms shall become effective
                  immediately upon their posting, subject to applicable laws.
                  You are responsible for regularly reviewing the Terms. If you
                  disagree with the revised Terms, whether in their entirety or
                  in part, you must cease using the QR link without delay.
                </p>
                <p className="text-start">
                  13. Severability If any court of competent jurisdiction
                  declares any provision of these Terms to be invalid, the
                  invalidity of such provision shall not affect the validity of
                  the remaining provisions, which shall remain in full force and
                  effect. Any waiver of any provision in these Terms shall not
                  be considered a continuing waiver of such provision or any
                  other provision.
                </p>

                <p className="text-start">
                  14. Governing Law The laws of the country or state in which
                  the Solution Details - Evolut POS entity that is party to
                  these Terms is domiciled shall govern and interpret these
                  Terms, without considering the principles of choice of law.
                  You consent that any legal actions or proceedings that arise
                  from or are related to these Terms shall be exclusively
                  submitted to the jurisdiction of the courts in the country
                  where the Solution Details - Evolut POS entity that is party
                  to these Terms is domiciled.
                </p>

                <p className="text-start">
                  15. Prevailing Language If there is a disagreement about the
                  Terms, the English version will take precedence. The English
                  version of these Terms will govern in all respects and will
                  prevail if there are any conflicts with translated versions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TOS;
