import { STORAGEKEY } from "../constants";
import api from "../api/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setFirstRender } from "../redux/authSlice";

const useAuthenticate = () => {
  const isAuthenticate = localStorage.getItem(STORAGEKEY.TOKEN);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const onLogin = async (payload) => {
    try {
      const bodyFormData = new FormData();

      bodyFormData.append("username", payload?.username);
      bodyFormData.append("password", payload?.password);

      const { data } = await api.postFromAuthURL(
        "/api/v1/auth/login",
        bodyFormData,
        {
          "Content-Type": "multipart/form-data",
        }
      );

      localStorage.setItem(STORAGEKEY.TOKEN, data?.access_token);

      return data;
    } catch (error) {
      toast.error("Login with wrong email or password. Please try again!");
      console.log(error);
    }
  };

  const onRegister = async (payload) => {
    try {
      const { data } = await api.postFromAuthURL(
        "/api/v1/auth/register",
        payload
      );

      navigate("/login");

      return data;
    } catch (error) {
      toast.error(error?.response?.data?.detail);
      console.log(error);
    }
  };

  const onForgotPassword = async (payload) => {
    try {
      const { data } = await api.postFromAuthURL(
        "/api/v1/auth/forgot-password",
        payload
      );

      toast.success(
        "An email for reset password has sent to your email successful"
      );

      return data;
    } catch (error) {
      toast.error("Some problem with the server. Please try again!");
      console.log(error);
    }
  };

  const onGetFacebookLoginUrl = async () => {
    try {
      const { data } = await api.getFromAuthURLWithParams(
        "/auth/facebook/authorize",
        {
          redirect_url: `${window.location.origin}/social-callback?type=facebook`,
        }
      );

      window.location.href = data?.authorization_url;
    } catch (error) {
      console.log(error);
    }
  };

  const onGetGoogleLoginUrl = async () => {
    try {
      const { data } = await api.getFromAuthURLWithParams(
        "/auth/google/authorize",
        {
          redirect_url: `${window.location.origin}/social-callback?type=google`,
        }
      );
      window.location.href = data?.authorization_url;
    } catch (error) {
      console.log(error);
    }
  };

  const onLoginWithFacebook = async (payload) => {
    try {
      const { data } = await api.getFromAuthURLWithParams(
        "/auth/facebook/callback",
        {
          redirect_url: `${window.location.origin}/social-callback?type=facebook`,
          ...payload,
        }
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };
  const onLoginWithGoogle = async (payload) => {
    try {
      const { data } = await api.getFromAuthURLWithParams(
        "/auth/google/callback",
        {
          redirect_url: `${window.location.origin}/social-callback?type=google`,
          ...payload,
        }
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const onLogout = async (noNavigate) => {
    try {
      await api.postFromAuthURL("/api/v1/auth/logout");
      if (!noNavigate) {
        navigate("/login");
      }
      localStorage.removeItem(STORAGEKEY.TOKEN);
      dispatch(setFirstRender(false));
    } catch (error) {
      console.log(error);
    }
  };

  return {
    isAuthenticate,
    onLogin,
    onRegister,
    onForgotPassword,
    onGetFacebookLoginUrl,
    onGetGoogleLoginUrl,
    onLoginWithFacebook,
    onLoginWithGoogle,
    onLogout,
  };
};

export default useAuthenticate;
