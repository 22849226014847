import { Divider } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "./styles.css";
import { FaFacebookF } from "react-icons/fa";
import { BsGoogle } from "react-icons/bs";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import useAuthenticate from "../../hooks/useAuthenticate";
import {
  QRCodeValidation,
  userSessionId,
} from "../../services/tableQRServices";
import { STORAGEKEY } from "../../constants";
import useBrandConfig from "../../hooks/useBrandConfig";

const RegisterScreen = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const {
    isAuthenticate,
    onRegister,
    onGetFacebookLoginUrl,
    onGetGoogleLoginUrl,
  } = useAuthenticate();
  const { config } = useBrandConfig();

  const qrCode = localStorage.getItem(STORAGEKEY.QR_CODE);
  const tableNo = localStorage.getItem(STORAGEKEY.TABLE_NO);
  const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

  const handleQRCodeValidationFunction = (qrCode, tableNo) => {
    QRCodeValidation(qrCode, tableNo)
      .then((res) => {
        const result = res?.data?.data[0];

        if (result.result === "FAIL") {
          navigate("/qr-not-valid");
        } else {
          navigate(
            config?.enableRedemption
              ? "/reward"
              : `/home?storename=${storeName}`
          );
        }
      })
      .catch(() => {});
  };

  const sessionCheckFunction = () => {
    if (qrCode && qrCode.length > 0) {
      handleQRCodeValidationFunction(qrCode, tableNo);
    } else if (!qrCode || qrCode.length <= 0) {
      navigate(
        config?.enableRedemption ? "/reward" : `/home?storename=${storeName}`
      );
    } else {
      navigate("/qr-not-valid");
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Email is required"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
      ),
    }),
    onSubmit: async (values) => {
      const success = await onRegister({
        email: values?.email,
        password: values?.password,
      });

      if (success) {
        sessionCheckFunction();
      }
    },
  });

  useEffect(() => {
    if (isAuthenticate) {
      navigate("/reward");
    }
  }, [isAuthenticate, navigate]);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="title-bg position-absolute w-100 start-0 z-index-1">
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/logo/logo.png`}
              alt=""
              className="auth-logo-img pt-2"
            />
          </div>
          <div className="position-absolute w-100 d-flex flex-column justify-content-center h-100 pe-3 ps-3">
            <Form
              className="d-flex flex-column gap-3"
              onSubmit={formik.handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label className="d-flex font-family-class fw-normal font-14 line-height-21 text-white">
                  Email
                </Form.Label>
                <Form.Control
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="eg: example@example.com"
                  className="font-family-class fw-normal font-14 line-height-21"
                />
                <Form.Text>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger font-family-class fw-normal font-12 line-height-17 mt-2 text-start">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="password">
                <Form.Label className="d-flex font-family-class fw-normal font-14 line-height-21 text-white">
                  Password
                </Form.Label>
                <div className="position-relative d-flex align-items-center">
                  <Form.Control
                    name="password"
                    type={isShowPassword ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    placeholder="Enter password here"
                    className="font-family-class fw-normal font-14 line-height-21"
                  />
                  {isShowPassword ? (
                    <AiOutlineEyeInvisible
                      onClick={() => setIsShowPassword(false)}
                      className="pointer-event position-absolute toggle-password"
                    />
                  ) : (
                    <AiOutlineEye
                      onClick={() => setIsShowPassword(true)}
                      className="pointer-event position-absolute toggle-password"
                    />
                  )}
                </div>
                <Form.Text>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger font-family-class fw-normal font-12 line-height-17 mt-2 text-start">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <Form.Group controlId="confirmPassword">
                <Form.Label className="d-flex font-family-class fw-normal font-14 line-height-21 text-white">
                  Confirm Password
                </Form.Label>
                <div className="position-relative d-flex align-items-center">
                  <Form.Control
                    name="confirmPassword"
                    type={isShowConfirmPassword ? "text" : "password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    placeholder="Enter confirm password here"
                    className="font-family-class fw-normal font-14 line-height-21"
                  />
                  {isShowConfirmPassword ? (
                    <AiOutlineEyeInvisible
                      onClick={() => setIsShowConfirmPassword(false)}
                      className="pointer-event position-absolute toggle-password"
                    />
                  ) : (
                    <AiOutlineEye
                      onClick={() => setIsShowConfirmPassword(true)}
                      className="pointer-event position-absolute toggle-password"
                    />
                  )}
                </div>
                <Form.Text>
                  {formik.touched.confirmPassword &&
                  formik.errors.confirmPassword ? (
                    <div className="text-danger font-family-class fw-normal font-12 line-height-17 mt-2 text-start">
                      {formik.errors.confirmPassword}
                    </div>
                  ) : null}
                </Form.Text>
              </Form.Group>
              <div className="d-flex flex-column-reverse gap-2">
                <button
                  type="submit"
                  disabled={!formik.isValid}
                  className="continue-as-guest d-flex justify-content-center text-white align-items-center">
                  Sign Up
                </button>
                <Link
                  className="text-white text-decoration-underline"
                  to="/login">
                  Already have an account?
                </Link>
              </div>
            </Form>
            <div className="d-flex flex-column mt-5 gap-2">
              <div className="d-flex align-items-center gap-2">
                <Divider className="flex-grow-1 bg-white" />
                <span className="text-white">Or Sign Up With</span>
                <Divider className="flex-grow-1 bg-white" />
              </div>
              <div className="d-flex gap-2">
                <button
                  className="facebook-login"
                  onClick={onGetFacebookLoginUrl}>
                  <FaFacebookF />
                  Facebook
                </button>
                <button className="google-login" onClick={onGetGoogleLoginUrl}>
                  <BsGoogle style={{ marginRight: 2 }} />
                  Google
                </button>
              </div>
            </div>
          </div>
          <img
            src={`/assets/${process.env.REACT_APP_MODE}/background/splash.png`}
            alt=""
            className="w-100 h-100"
          />
        </div>
      </div>
    </>
  );
};

export default RegisterScreen;
