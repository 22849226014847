import React from "react";
import { Button, Modal } from "react-bootstrap";
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from "react-icons/io"; // Import specific icons

const ConfirmModal = ({ title, description, onCancel, onConfirm, open }) => {
  // Style for the confirm icon
  const confirmIconStyle = {
    fontSize: "60px",
    color: "#4CAF50", // Green color for confirm icon
  };

  // Style for the cancel icon
  const cancelIconStyle = {
    fontSize: "60px",
    color: "#f44336", // Red color for cancel icon
  };

  // Common text style for both button texts
  const textStyle = {
    display: "block",
    fontSize: "16px",
  };

  // Common button style for both buttons
  const buttonStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
    width: "100px",
    height: "100px",
    padding: "10px",
    // border: "none",
    borderRadius: "5px",
    backgroundColor: "white",
    boxShadow: "0 10px rgba(0, 0, 0, 0.1)",
    // solid border with 2px width
    border: "2px solid",
    // black text
    color: "black",
  };

  // Footer style to align buttons
  const footerStyle = {
    display: "flex",
    justifyContent: "space-evenly",
  };

  return (
    <Modal show={open} onHide={onCancel} centered className="customize-content">
      <Modal.Header closeButton className="modal-border" closeVariant="white">
        <Modal.Title style={{ fontSize: "20px", textTransform: "uppercase" }}>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          fontSize: "18px",
          textTransform: "uppercase",
          fontWeight: "bold",
        }}
      >
        {description}
      </Modal.Body>
      <Modal.Footer style={footerStyle}>
        <Button
          onClick={onConfirm}
          style={buttonStyle}
          className="text-uppercase fw-bold"
        >
          <IoMdCheckmarkCircleOutline style={confirmIconStyle} />
          <span style={textStyle}>Confirm</span>
        </Button>
        <Button
          onClick={onCancel}
          style={buttonStyle}
          className="text-uppercase fw-bold"
        >
          <IoMdCloseCircleOutline style={cancelIconStyle} />
          <span style={textStyle}>Cancel</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
