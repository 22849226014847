import React, { useCallback, useEffect } from "react";
import { STORAGEKEY } from "../../constants";
import { useNavigate } from "react-router-dom";
import useAuthenticate from "../../hooks/useAuthenticate";
import {
  QRCodeValidation,
  userSessionId,
} from "../../services/tableQRServices";
import useBrandConfig from "../../hooks/useBrandConfig";

const SocialCallbackScreen = () => {
  const { isAuthenticate, onLoginWithFacebook, onLoginWithGoogle } =
    useAuthenticate();
  const navigate = useNavigate();

  const { config } = useBrandConfig();

  const params = window.location.search;
  const state = new URLSearchParams(params).get("state");
  const code = new URLSearchParams(params).get("code");
  const type = new URLSearchParams(params).get("type");
  const qrCode = localStorage.getItem(STORAGEKEY.QR_CODE);
  const tableNo = localStorage.getItem(STORAGEKEY.TABLE_NO);
  const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

  const handleQRCodeValidationFunction = useCallback(
    (qrCode, tableNo) => {
      QRCodeValidation(qrCode, tableNo)
        .then((res) => {
          const result = res?.data?.data[0];

          if (result.result === "FAIL") {
            navigate("/qr-not-valid");
          } else {
            navigate(
              config?.enableRedemption
                ? "/reward"
                : `/home?storename=${storeName}`
            );
          }
        })
        .catch(() => {});
    },
    [navigate]
  );

  const sessionCheckFunction = useCallback(() => {
    if (qrCode && qrCode.length > 0) {
      handleQRCodeValidationFunction(qrCode, tableNo);
    } else if (!qrCode || qrCode.length <= 0) {
      navigate(
        config?.enableRedemption ? "/reward" : `/home?storename=${storeName}`
      );
    } else {
      navigate("/qr-not-valid");
    }
  }, [handleQRCodeValidationFunction, navigate, qrCode, tableNo]);

  const handleSocialCallback = useCallback(async () => {
    if (type === "facebook") {
      const data = await onLoginWithFacebook({
        state,
        code,
      });

      if (data?.access_token) {
        localStorage.setItem(STORAGEKEY.TOKEN, data?.access_token);
      }
    }

    if (type === "google") {
      const data = await onLoginWithGoogle({
        state,
        code,
      });

      if (data?.access_token) {
        localStorage.setItem(STORAGEKEY.TOKEN, data?.access_token);
      }
    }

    sessionCheckFunction();
  }, [
    code,
    onLoginWithFacebook,
    onLoginWithGoogle,
    sessionCheckFunction,
    state,
    type,
  ]);

  useEffect(() => {
    if (!isAuthenticate) {
      handleSocialCallback();
    } else {
      navigate(
        config?.enableRedemption ? "/reward" : `/home?storename=${storeName}`
      );
    }
  }, [navigate, type, isAuthenticate]);

  return <></>;
};

export default SocialCallbackScreen;
