import { STORAGEKEY } from "../../constants";
import { apiCall } from "../../lib/supabase";

export const getBrandConfig = async () => {
  const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

  const { data: dataBrand } = await apiCall
    .from("brand")
    .select("id")
    .eq("name", process.env.REACT_APP_MODE);
  const { data: dataBrandConfig } = await apiCall
    .from("brand_config")
    .select("*")
    .eq("brand_id", dataBrand?.[0]?.id);
  const { data: dataOutlet } = await apiCall
    .from("outlet")
    .select("*")
    .eq("brand_id", dataBrand?.[0]?.id)
    .like("name", storeName);

  const brandConfig = dataBrandConfig?.[0];
  const outlet = dataOutlet?.[0];

  const data = {
    config: {
      ...brandConfig?.tqr_config,
      storeClosed: outlet?.store_closed,
      lottieUrl: brandConfig?.lottie_url,
      authProvider: brandConfig?.auth_provider || [],
      countryCodes: brandConfig?.country_codes ?? [],
      qlubPaymentURL: brandConfig?.qlub_payment_url ?? "",
    },
    welcomeBanner: brandConfig?.welcome_banner,
    iconConfig: brandConfig?.icon_config,
    recommendations: brandConfig?.recommendations_config,
  };

  return data;
};
