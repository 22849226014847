import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    firstRender: false,
    availablePoints: 0,
  },
  reducers: {
    setFirstRender: (state, action) => {
      state.firstRender = action.payload;
    },
    setAvailablePoints: (state, action) => {
      state.availablePoints = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;
export const { setFirstRender, setAvailablePoints } = authSlice.actions;
