import React, { useEffect, useState } from "react";
import checkIfImageExists from "../../utils/checkIfImageExists";

const CartItemImage = ({ supaProducts, data }) => {
  const defaultImage = `/assets/${process.env.REACT_APP_MODE}/logo/logo.png`;

  const [imageUrl, setImageUrl] = useState();
  const [isDefaultImage, setIsDefaultImage] = useState(false);

  const findProduct = supaProducts?.[data?.item_no];
  const originalImageUrl = findProduct?.image_url;

  useEffect(() => {
    checkIfImageExists(originalImageUrl, (exists) => {
      setIsDefaultImage(!exists);

      if (exists) {
        setImageUrl(originalImageUrl);
      } else {
        setImageUrl(defaultImage);
      }
    });
  }, [originalImageUrl]);

  return (
    imageUrl && (
      <img
        src={imageUrl}
        alt=""
        className={`cart-img ${isDefaultImage ? "image-contain" : ""}`}
      />
    )
  );
};

export default CartItemImage;
