import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../services/supabase/category";
import { get } from "lodash";
import { STORAGEKEY } from "../constants";

function HeaderMenu(props) {
  const { menuItem, subCategory } = props;
  const navigate = useNavigate();
  const [allCategories, setAllCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // category image
  const fetchSupabaseCategories = async () => {
    setIsLoading(true);
    const resp = await getCategories(process.env.REACT_APP_MODE);
    setAllCategories(
      resp.data?.filter(
        (record) => record?.is_hidden !== true && record?.is_hidden !== "Y"
      )
    );
  };
  useEffect(() => {
    fetchSupabaseCategories();
  }, []);

  // function to get the category image based on name
  const getCategoryImage = (categoryName) => {
    const category = allCategories.find(
      (category) => category.name === categoryName
    );
    return category?.image_url;
  };
  // function to get the category short name based on name
  const getCategoryShortName = (categoryName) => {
    const category = allCategories.find(
      (category) => category.name === categoryName
    );
    return category?.name;
  };

  // handle click of item in menu and navigate to the page with item as state
  const handleMenuItemClick = (category) => {
    navigate("/category", {
      state: { category: category },
    });
  };

  function insertLineBreak(category) {
    if (category.includes("(")) {
      return category.replace("(", "\n(");
    } else {
      return category;
    }
  }

  const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

  return (
    <>
      <div
        className="d-flex header-menu sticky-top"
        style={{
          position: "fixed",
          top: 77,
        }}>
        <div
          key="home"
          className="header-menu-item d-flex flex-column align-items-center main-category"
          // style={{minWidth: '100px'}}
          onClick={() => navigate(`/home?storename=${storeName}`)}>
          <div className="image-container">
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`}
              alt="Your Alt Text"
              className="header-category-image image-contain"
            />
          </div>
          <span> ALL</span>
        </div>
        {menuItem.map(
          (category, index) =>
            getCategoryShortName(category) && (
              <div
                onClick={() => handleMenuItemClick(category)}
                key={index}
                className="header-menu-item d-flex flex-column align-items-center main-category">
                <div className="image-container">
                  <img
                    src={
                      getCategoryImage(category) ||
                      `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`
                    }
                    alt="Your Alt Text"
                    className={`header-category-image ${
                      !getCategoryImage(category) ? "image-contain" : ""
                    }`}
                  />
                </div>
                <span>
                  {/* {insertLineBreak(category)} */}
                  {/* use short name for category if present, else use category */}
                  {getCategoryShortName(category)}
                </span>
              </div>
            )
        )}
      </div>
      {subCategory?.length > 0 && (
        <div
          className="d-flex header-menu sticky-top gap-1"
          style={{
            position: "fixed",
            top: 145,
            minHeight: 60,
          }}>
          {subCategory?.map(
            (category, index) =>
              getCategoryShortName(category) && (
                <div
                  onClick={() => handleMenuItemClick(category)}
                  key={index}
                  className="header-menu-item d-flex align-items-center border sub-category"
                  style={{
                    height: 25,
                    borderRadius: 20,
                  }}>
                  {/* <div className="image-container">
                    <img
                      src={
                        getCategoryImage(category) ||
                        `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`
                      }
                      alt="Your Alt Text"
                      className={`header-category-image ${
                        !getCategoryImage(category) ? "image-contain" : ""
                      }`}
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    />
                  </div> */}
                  <span className="text-nowrap text-black">
                    {/* {insertLineBreak(category)} */}
                    {/* use short name for category if present, else use category */}
                    {getCategoryShortName(category)}
                  </span>
                </div>
              )
          )}
        </div>
      )}
    </>
  );
}

export default HeaderMenu;
