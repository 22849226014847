import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  config: {},
  welcomeBanner: "",
  iconConfig: {},
  recommendations: [],
  openConfetti: false,
  storeClosed: false,
  categoriesMenu: [],
  currentVoucher: {},
  promotionConfig: [],
  openFreeItemByValueModal: false,
  firstTimeOpenFreeItemByValueModal: false,
  openFreeItemWithLimitModal: false,
  firstTimeOpenFreeItemWithLimitModal: false,
  redeemableList: [],
  openLottieAddCart: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      state.config = action.payload;
    },
    setWelcomeBanner: (state, action) => {
      state.welcomeBanner = action.payload;
    },
    setIconConfig: (state, action) => {
      state.iconConfig = action.payload;
    },
    setRecommendations: (state, action) => {
      state.recommendations = action.payload;
    },
    setOpenConfetti: (state, action) => {
      state.openConfetti = action.payload;
    },
    setStoreClosed: (state, action) => {
      state.storeClosed = action.payload;
    },
    setCategoriesMenu: (state, action) => {
      state.categoriesMenu = action.payload;
    },
    setCurrentVoucher: (state, action) => {
      state.currentVoucher = action.payload;
    },
    setOpenFreeItemByValueModal: (state, action) => {
      state.openFreeItemByValueModal = action.payload;
    },
    setFirstTimeOpenFreeItemByValueModal: (state, action) => {
      state.firstTimeOpenFreeItemByValueModal = action.payload;
    },
    setPromotionConfig: (state, action) => {
      state.promotionConfig = action.payload;
    },
    setOpenFreeItemWithLimitModal: (state, action) => {
      state.openFreeItemWithLimitModal = action.payload;
    },
    setFirstTimeOpenFreeItemWithLimitModal: (state, action) => {
      state.firstTimeOpenFreeItemWithLimitModal = action.payload;
    },
    setRedeemableList: (state, action) => {
      state.redeemableList = action.payload;
    },
    setOpenLottieAddCart: (state, action) => {
      state.openLottieAddCart = action.payload;
    },
    resetAppState: (state) => {
      state.config = initialState.config;
      state.welcomeBanner = initialState.welcomeBanner;
      state.iconConfig = initialState.iconConfig;
      state.recommendations = initialState.recommendations;
      state.openConfetti = initialState.openConfetti;
      state.storeClosed = initialState.storeClosed;
      state.categoriesMenu = initialState.categoriesMenu;
      state.currentVoucher = initialState.currentVoucher;
      state.promotionConfig = initialState.promotionConfig;
      state.openFreeItemByValueModal = initialState.openFreeItemByValueModal;
      state.firstTimeOpenFreeItemByValueModal =
        initialState.firstTimeOpenFreeItemByValueModal;
      state.openFreeItemWithLimitModal =
        initialState.openFreeItemWithLimitModal;
      state.firstTimeOpenFreeItemWithLimitModal =
        initialState.firstTimeOpenFreeItemWithLimitModal;
      state.redeemableList = initialState.redeemableList;
      state.openLottieAddCart = initialState.openLottieAddCart;
    },
  },
});

export const appReducer = appSlice.reducer;
export const {
  setConfig,
  setWelcomeBanner,
  setIconConfig,
  setRecommendations,
  setOpenConfetti,
  setStoreClosed,
  setCategoriesMenu,
  setCurrentVoucher,
  setOpenFreeItemByValueModal,
  setFirstTimeOpenFreeItemByValueModal,
  setPromotionConfig,
  setOpenFreeItemWithLimitModal,
  setFirstTimeOpenFreeItemWithLimitModal,
  setRedeemableList,
  setOpenLottieAddCart,
  resetAppState,
} = appSlice.actions;
