import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  posStoreStockList: "",
  posMenuButton: [],
  posFullItemList: [],
  supabaseProducts: [],
};

const posSlice = createSlice({
  name: "auth",
  initialState: {
    posStoreStockList: "",
    posMenuButton: [],
    posFullItemList: [],
    supabaseProducts: [],
  },
  reducers: {
    setPosStoreStockList: (state, action) => {
      state.posStoreStockList = action.payload;
    },
    setPosMenuButton: (state, action) => {
      state.posMenuButton = action.payload;
    },
    setPosFullItemList: (state, action) => {
      state.posFullItemList = action.payload;
    },
    setSupabaseProducts: (state, action) => {
      state.supabaseProducts = action.payload;
    },
    resetState: (state) => {
      state.posStoreStockList = initialState.posStoreStockList;
      state.posMenuButton = initialState.posMenuButton;
      state.posFullItemList = initialState.posFullItemList;
      state.supabaseProducts = initialState.supabaseProducts;
    },
  },
});

export const posReducer = posSlice.reducer;
export const {
  setPosStoreStockList,
  setPosMenuButton,
  setPosFullItemList,
  setSupabaseProducts,
  resetState: resetPosState,
} = posSlice.actions;
