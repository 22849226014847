import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  QRCodeValidation,
  storeMenuButton,
  storeMenuItems,
  storeStockList,
  userSessionId,
} from "../../services/tableQRServices";
import { STORAGEKEY } from "../../constants";
import { Form, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import { Link } from "react-router-dom";
import cx from "classnames";
import useAuthenticate from "../../hooks/useAuthenticate";
import useBrandConfig from "../../hooks/useBrandConfig";
import { resetState } from "../../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  resetPosState,
  setPosFullItemList,
  setPosMenuButton,
  setPosStoreStockList,
  setSupabaseProducts,
} from "../../redux/posSlice";
import { getProducts } from "../../services/supabase/product";
import {
  resetAppState,
  setConfig,
  setOpenLottieAddCart,
  setPromotionConfig,
} from "../../redux/appSlice";
import { toast } from "react-toastify";
import usePromotion from "../../hooks/usePromotion";
import { apiCall } from "../../lib/supabase";
import moment from "moment";
import { roundNumber } from "../../utils/numberUtils";

function SplashScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let params = window.location.search;
  const [loading, setLoading] = useState(true);
  const [isQRError, setIsQRError] = useState(false);

  const { onLogout } = useAuthenticate();
  const { config, handleCheckStoreClosed, handleGetConfig } = useBrandConfig();
  const { storeClosed } = useSelector((state) => state.app);
  const { handleGetPromotionConfig } = usePromotion();

  const queries = new URLSearchParams(params);
  let storeName = queries.get("outlet");
  let tableNo = queries.get("table_no");
  const qrCode = queries.get("QRCode");

  // temp support
  const storeNameTemp = queries.get("storename");
  if (storeNameTemp && storeNameTemp.length > 0) {
    storeName = storeNameTemp;
  }
  const tableNoTemp = queries.get("tableno");
  if (tableNoTemp && tableNoTemp.length > 0) {
    tableNo = tableNoTemp;
  }

  if (storeNameTemp === "Sembawang SCs") {
    localStorage.setItem(STORAGEKEY.STORE_NAME, "Sembawang SC");
    navigate(`?storename=Sembawang SC&tableno=${tableNo}`, { replace: true });
  }
  if (storeNameTemp === "Jcube") {
    localStorage.setItem(STORAGEKEY.STORE_NAME, "JCube");
    navigate(`?storename=JCube&tableno=${tableNo}`, { replace: true });
  }

  const handleGetStockList = async () => {
    try {
      const res = await storeStockList();

      const result = res?.data?.data[0]?.output;

      dispatch(setPosStoreStockList(result));

      return JSON.parse(result || "[]")?.length > 0;
    } catch (error) {
      if (typeof error === "string") {
        toast.error(error);

        return;
      } else {
        navigate("/qr-not-valid");

        return false;
      }
    }
  };

  const handleGetMenuButton = async () => {
    try {
      const res = await storeMenuButton();

      const categoryMenuButton = res?.data?.data[0]?.output;

      dispatch(setPosMenuButton(categoryMenuButton));

      return categoryMenuButton?.length > 0;
    } catch (error) {
      if (typeof error === "string") {
        toast.error(error);

        return;
      } else {
        navigate("/qr-not-valid");

        return false;
      }
    }
  };

  const handleGetMenuItems = async () => {
    try {
      const res = await storeMenuItems();

      const result = res?.data?.data[0]?.output;

      dispatch(setPosFullItemList(result));

      return result?.length > 0;
    } catch (error) {
      if (typeof error === "string") {
        toast.error(error);

        return;
      } else {
        navigate("/qr-not-valid");

        return false;
      }
    }
  };

  // ? condition redirect after splash time over
  const QRCodeValidationFunction = (qrCode, tableNo) => {
    QRCodeValidation(qrCode, tableNo)
      .then((res) => {
        const result = res?.data?.data[0];
        const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

        if (result.result === "FAIL") {
          setIsQRError(true);
          navigate("/qr-not-valid");
        } else {
          navigate(`/home?storename=${storeName}`);
        }
      })
      .catch(() => {});
  };

  const sessionCheckFunction = async () => {
    const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);

    if (qrCode && qrCode.length > 0) {
      QRCodeValidationFunction(qrCode, tableNo);
    } else if (!qrCode || qrCode.length <= 0) {
      navigate(`/home?storename=${storeName}`);
    } else {
      navigate("/qr-not-valid");
    }
  };

  const formik = useFormik({
    initialValues: { terms_services: false, promotional: true },
    // validationSchema: Yup.object({
    //   terms_services: Yup.boolean().oneOf(
    //     [true],
    //     "The terms of service must be accepted"
    //   ),
    //   // promotional: Yup.boolean().oneOf(
    //   //   [true],
    //   //   "I do not wish to receive any marketing or promotional materials must be accepted"
    //   // ),
    // }),
    onSubmit: async (values) => {
      setLoading(true);

      await handleGetPromotionConfig();

      const dataStockList = await handleGetStockList();
      const dataMenuButton = await handleGetMenuButton();
      const dataMenuItems = await handleGetMenuItems();

      if (!dataStockList || !dataMenuButton || !dataMenuItems) {
        if (
          typeof dataStockList !== "boolean" ||
          typeof dataMenuButton !== "boolean" ||
          typeof dataMenuItems !== "boolean"
        ) {
          setLoading(false);

          return;
        }

        navigate("/qr-not-valid");

        setLoading(false);

        return;
      }

      await sessionCheckFunction();

      setLoading(false);

      localStorage.setItem(
        STORAGEKEY.SESSION_TIMEOUT,
        new Date().toISOString()
      );
    },
  });

  const handleLocalStorage = async () => {
    dispatch(resetState());
    dispatch(resetPosState());
    dispatch(setPromotionConfig([]));
    dispatch(setOpenLottieAddCart(false));
    dispatch(resetAppState());

    localStorage.removeItem(STORAGEKEY.TABLE_NO);
    localStorage.removeItem(STORAGEKEY.STORE_NAME);
    localStorage.removeItem(STORAGEKEY.DINING_MODE);

    localStorage.clear();

    if (tableNo === "takeaway") {
      localStorage.setItem(STORAGEKEY.DINING_MODE, tableNo);
    }

    localStorage.setItem(
      STORAGEKEY.STORE_NAME,
      storeName && storeName.length > 0 ? storeName : "BUGIS JUNCTION"
    );
    localStorage.setItem(STORAGEKEY.QR_CODE, qrCode);
    localStorage.setItem(STORAGEKEY.TABLE_NO, tableNo);

    await fetchSupabaseProducts();
  };

  const fetchSupabaseProducts = async () => {
    const resp = await getProducts(process.env.REACT_APP_MODE);

    if (resp?.data?.length !== 0) {
      // look for items where is_top_pick is true
      const topPicks = resp?.data?.filter((item) => item.is_top_pick);
      const topPicksIds = topPicks?.map((item) => item.pos_item_no);

      dispatch(setSupabaseProducts(topPicksIds));
    }
  };

  useEffect(() => {
    onLogout(true);
    handleGetConfig();
  }, []);

  useEffect(() => {
    if (
      (config?.qr_type === "dynamic" && (!qrCode || qrCode.length <= 0)) ||
      !tableNo
    ) {
      navigate("/qr-not-valid");
    }
  }, [config?.qr_type, navigate, qrCode, tableNo]);

  const handleGetSessionId = () => {
    userSessionId()
      .then((res) => {
        localStorage.setItem(STORAGEKEY.SESSION_ID, res);

        handleCheckStoreClosed();

        setLoading(false);
      })
      .catch((_err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!storeName) {
      navigate("/storename-not-valid");
    } else {
      handleLocalStorage();
      // set the session id
      setLoading(true);
      handleGetSessionId();
    }
  }, []);

  // const handleGetReport = async () => {
  //   const response = await apiCall
  //     .from("pos_log")
  //     .select("*")
  //     .gte(
  //       "created_at",
  //       new Date(moment().subtract(1, "months")).toISOString()
  //     );
  //   const responseBrand = await apiCall.from("brand").select("*");

  //   if (response?.data) {
  //     const result = response?.data?.filter((record) => {
  //       const payload = record?.payload;
  //       const salesDetails = payload?.sales_dtls;
  //       const masterSubTotal = roundNumber(
  //         salesDetails?.reduce((a, b) => (a += Number(b?.sub_total)), 0)
  //       );
  //       const masterTaxTotal = roundNumber(
  //         salesDetails?.reduce((a, b) => (a += Number(b?.tax_amt)), 0)
  //       );
  //       const masterServiceChargeTotal = roundNumber(
  //         salesDetails?.reduce((a, b) => (a += Number(b?.svc_amt) || 0), 0)
  //       );

  //       const originalMasterSubTotal = payload?.sub_total;
  //       const originalMasterTaxTotal = payload?.total_tax;
  //       const originalMasterServiceChargeTotal = payload?.total_svc;

  //       let totalNetAmt = masterSubTotal + masterServiceChargeTotal;

  //       if (payload?.absorb_tax !== "Y") {
  //         totalNetAmt += masterTaxTotal;
  //       }

  //       return (
  //         roundNumber(originalMasterSubTotal) !== roundNumber(masterSubTotal) ||
  //         roundNumber(masterTaxTotal) !== roundNumber(originalMasterTaxTotal) ||
  //         roundNumber(masterServiceChargeTotal) !==
  //           roundNumber(originalMasterServiceChargeTotal) ||
  //         roundNumber(totalNetAmt) !== roundNumber(payload?.net_amt)
  //       );
  //     });
  //     const finalResult = result?.map((record) => {
  //       const findBrand = responseBrand?.data?.find(
  //         (brand) => brand?.id === record?.brand
  //       );
  //       const payload = record?.payload;
  //       const salesDetails = payload?.sales_dtls;
  //       const masterSubTotal = roundNumber(
  //         salesDetails?.reduce((a, b) => (a += Number(b?.sub_total)), 0)
  //       );
  //       const masterTaxTotal = roundNumber(
  //         salesDetails?.reduce((a, b) => (a += Number(b?.tax_amt)), 0)
  //       );
  //       const masterServiceChargeTotal = roundNumber(
  //         salesDetails?.reduce((a, b) => (a += Number(b?.svc_amt) || 0), 0)
  //       );

  //       const originalMasterSubTotal = payload?.sub_total;
  //       const originalMasterTaxTotal = payload?.total_tax;
  //       const originalMasterServiceChargeTotal = payload?.total_svc;

  //       let totalNetAmt = masterSubTotal + masterServiceChargeTotal;

  //       if (payload?.absorb_tax !== "Y") {
  //         totalNetAmt += masterTaxTotal;
  //       }

  //       const differentCalculation = {};

  //       if (
  //         roundNumber(originalMasterSubTotal) !== roundNumber(masterSubTotal)
  //       ) {
  //         differentCalculation.sub_total = {
  //           master: roundNumber(originalMasterSubTotal),
  //           sale_dtls: roundNumber(masterSubTotal),
  //         };
  //       }

  //       if (
  //         roundNumber(masterTaxTotal) !== roundNumber(originalMasterTaxTotal)
  //       ) {
  //         differentCalculation.tax_total = {
  //           master: roundNumber(originalMasterTaxTotal),
  //           sale_dtls: roundNumber(masterTaxTotal),
  //         };
  //       }

  //       if (
  //         roundNumber(masterServiceChargeTotal) !==
  //         roundNumber(originalMasterServiceChargeTotal)
  //       ) {
  //         differentCalculation.service_charge_total = {
  //           master: roundNumber(originalMasterServiceChargeTotal),
  //           sale_dtls: roundNumber(masterServiceChargeTotal),
  //         };
  //       }

  //       if (roundNumber(totalNetAmt) !== roundNumber(payload?.net_amt)) {
  //         differentCalculation.net_amt = {
  //           master: roundNumber(payload?.net_amt),
  //           sale_dtls: roundNumber(totalNetAmt),
  //         };
  //       }

  //       return {
  //         brand: findBrand?.name,
  //         outlet: record?.store_name,
  //         differentCalculation,
  //       };
  //     });

  //     console.log("finalResult", finalResult);
  //   }
  // };

  // useEffect(() => {
  //   handleGetReport();
  // }, []);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <div className="title-bg position-absolute w-100 start-0 z-index-1">
            {/* {process.env.REACT_APP_MODE === "pavilion" && (
              <label className="position-absolute start-0 end-0 title-margin font-28 fw-bold line-height-45 hotel-title">
                The Authentic <br />
                South Indian Restaurant
              </label>
            )} */}
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/logo/logo.png`}
              alt=""
              className="splash-logo-img mt-3 pt-2"
            />
          </div>
          <div className="h-50 position-absolute w-100 d-flex flex-column justify-content-end pb-5">
            {storeClosed ? (
              <div
                className="position-fixed w-100"
                style={{
                  bottom: "4vh",
                }}>
                <div className="d-flex w-100 d-flex justify-content-center align-content-center">
                  <span
                    className="splash-text text-center text-black"
                    style={{
                      fontSize: 20,
                      maxWidth: "90%",
                    }}>
                    <strong>
                      We are not taking orders on the app at this moment. <br />
                      Please approach the restaurant staff to place your order
                    </strong>
                  </span>
                </div>
              </div>
            ) : (
              <>
                <div className="terms-service">
                  <Form className="" onSubmit={formik.handleSubmit}>
                    {config?.enableLogin && (
                      <button
                        onClick={() => navigate("/login")}
                        className="continue-as-guest login-btn d-flex justify-content-center text-white align-items-center mb-2 mt-4 me-3 ms-3">
                        Login
                      </button>
                    )}
                    <button
                      type="submit"
                      disabled={!formik.isValid || loading}
                      className={cx(
                        "continue-as-guest d-flex justify-content-center text-white align-items-center mb-2 me-3 ms-3",
                        {
                          "mt-4": !config?.enableLogin,
                        }
                      )}
                      style={{
                        gap: 8,
                      }}>
                      <span>Continue as Guest</span>
                      {loading && (
                        <Spinner
                          className="splash-spinner align-self-center"
                          style={{
                            width: 24,
                            height: 24,
                          }}
                        />
                      )}
                    </button>
                    {config?.enableLogin && (
                      <Link
                        className="text-black text-decoration-underline"
                        to="/register">
                        Register Now
                      </Link>
                    )}
                    <div className="pt-2 pb-2 ms-3 me-4 d-flex ps-4">
                      <span className="splash-text text-start text-black">
                        By clicking "Continue as Guest" I agree to Solution
                        Details'{" "}
                        <strong>
                          <Link to="/terms-of-use" className="text-black">
                            Terms of Use
                          </Link>
                        </strong>{" "}
                        and{" "}
                        <strong>
                          <Link to="/privacy-policy" className="text-black">
                            Privacy Policy.
                          </Link>
                        </strong>
                      </span>
                    </div>
                    <Form.Group>
                      <div className="pb-2 d-flex ms-3 me-4">
                        <Form.Check
                          type="checkbox"
                          id="promotional"
                          name="promotional"
                          value={`promotional`}
                          label=""
                          onChange={formik.handleChange}
                        />
                        <span className="splash-text text-start text-black">
                          I do not wish to receive any marketing or promotional
                          materials.
                        </span>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
                <div className="powered-by-evolut position-fixed d-flex justify-content-center bottom-0 align-items-center">
                  <span>
                    Powered by Solution Details - Evolut POS & Intelli Table QR
                  </span>
                </div>
              </>
            )}
          </div>
          <div
            class={`splash-img-container w-100 ${
              storeClosed ? "splash-img-closed-container" : ""
            }`}>
            <img
              src={`/assets/${process.env.REACT_APP_MODE}/background/splash.png`}
              alt=""
              className="w-100 img-splash"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default SplashScreen;
