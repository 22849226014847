import React from "react";
import ButtonOption from "../../component/ButtonOption/ButtonOption";
import { BsDashLg, BsPlusLg } from "react-icons/bs";
import cx from "classnames";
import { useSelector } from "react-redux";

const CustomizeDishMultiple = ({
  index,
  stepperMenuIcon,
  customData,
  formik,
  setNumChecked,
  decrementCounter,
  getQtyItem,
  getQtyItemModifier,
  getGroupQty,
  incrementCounter,
  custom,
  numChecked,
  defaultIcon,
  dineOption,
}) => {
  const cartData = useSelector((state) => state.cart.cart);

  const cartItemData = cartData.filter(
    (item) => customData.citem_no === item.id
  );
  const cartItemDataQty = cartItemData?.reduce(
    (total, record) => (total += record?.qty),
    0
  );
  const addOnValues = [].concat.apply(
    [],
    cartData?.map((record) => record?.addOnValues)
  );
  const addOnValuesQty = addOnValues
    ?.filter((item) => customData.citem_no === item.item_no)
    ?.reduce((total, record) => (total += record?.modifier_qty), 0);
  const finalQty = addOnValuesQty + cartItemDataQty;

  const currentStepperMenuIcon =
    stepperMenuIcon?.[
      customData.modifier_name
        .replace(/[^\w\s]/gi, "")
        ?.split(" ")
        ?.join("")
        ?.split("")
        ?.map((record) => record?.toLowerCase())
        ?.join("")
    ];
  const currentMenuTypeIcon = currentStepperMenuIcon?.find((record) =>
    Array.isArray(record?.id)
      ? record?.id?.includes(customData?.citem_no)
      : record?.id === customData?.citem_no
  );

  const isMultipleActive = formik.values[customData.modifier_name].includes(
    customData.citem_name
  );

  console.log("customData", customData);

  return (
    <div className="ps-3 pe-4 pb-3">
      <div className="pt-3 pb-2 d-flex justify-content-between div-border align-items-center gap-2">
        <div className="d-flex gap-3">
          <ButtonOption
            id={index}
            value={customData.citem_name}
            name={customData.modifier_name}
            checked={isMultipleActive}
            onClick={(name, value, event) => {
              if (!isMultipleActive) {
                if (
                  custom.max_qty !== 0 &&
                  formik.values[customData.modifier_name].length >=
                    custom.max_qty
                ) {
                  event.preventDefault();
                  return;
                }
                setNumChecked(numChecked + customData.qty);
              } else {
                setNumChecked(numChecked - customData.qty);
              }

              if (!isMultipleActive) {
                formik.setFieldValue(name, [
                  ...(formik.values?.[customData.modifier_name] || []),
                  value,
                ]);
              } else {
                formik.setFieldValue(
                  customData.modifier_name,
                  formik.values?.[customData.modifier_name]?.filter(
                    (record) => record !== customData.citem_name
                  )
                );
              }
            }}
            iconOptions={{
              ...(currentMenuTypeIcon || {
                type: "svg",
              }),
              icon: isMultipleActive
                ? currentMenuTypeIcon?.activeIcon || defaultIcon?.activeIcon
                : currentMenuTypeIcon?.originalIcon ||
                  defaultIcon?.originalIcon,
            }}
          />
          <div className="d-flex flex-column gap-1 justify-content-center">
            <div className="d-flex flex-column justify-content-start gap-2">
              <span className="item-name">{customData.citem_name} </span>
              {customData?.bal_qty > 0 ? (
                <span
                  className="bal-qty-text text-uppercase"
                  style={{
                    width: "fit-content",
                  }}>
                  {finalQty >= customData?.bal_qty
                    ? "SOLD OUT"
                    : `(${customData?.bal_qty - finalQty} left)`}
                </span>
              ) : (
                ""
              )}
            </div>
            {formik.values?.[custom?.modifier_name]?.includes(
              customData?.citem_name
            ) && (
              <div
                className="left-mb-1"
                style={{
                  width: "fit-content",
                }}>
                <BsDashLg
                  className="qty-class me-3"
                  onClick={() =>
                    decrementCounter(
                      custom?.modifier_name,
                      customData?.citem_name
                    )
                  }
                />
                <label className="cart-item-qty">
                  {getQtyItem(custom?.modifier_name, customData?.citem_name)}
                </label>
                <BsPlusLg
                  className={cx("qty-class ms-3", {
                    "qty-class-disable":
                      custom.max_qty !== 0 &&
                      getQtyItemModifier(custom?.modifier_name) ===
                        custom.max_qty,
                  })}
                  onClick={() => {
                    if (
                      custom.max_qty !== 0 &&
                      getQtyItemModifier(
                        custom?.modifier_name,
                        customData?.citem_name
                      ) === custom.max_qty
                    ) {
                      return;
                    }

                    if (custom?.group_limit === 0) {
                      return incrementCounter(
                        custom?.modifier_name,
                        customData?.citem_name
                      );
                    }

                    if (
                      getQtyItemModifier(
                        custom?.modifier_name,
                        customData?.citem_name
                      ) +
                        getGroupQty(
                          custom?.modifier_name,
                          customData?.citem_name
                        ) <
                      custom?.group_limit
                    ) {
                      return incrementCounter(
                        custom?.modifier_name,
                        customData?.citem_name
                      );
                    }

                    if (custom.max_qty === 0 && customData.max_qty === 0) {
                      return incrementCounter(
                        custom?.modifier_name,
                        customData?.citem_name
                      );
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {dineOption === "takeaway" ? (
          <span className="customize-item-citen-name text-uppercase">
            {customData?.price_dtls[0]?.dine_in_price > 0
              ? `+$${(customData?.price_dtls[0]?.takeaway_price).toFixed(2)}`
              : ""}
          </span>
        ) : (
          <span className="customize-item-citen-name text-uppercase">
            {customData?.price_dtls[0]?.dine_in_price > 0
              ? `+$${(customData?.price_dtls[0]?.dine_in_price).toFixed(2)}`
              : ""}
          </span>
        )}
      </div>
    </div>
  );
};

export default CustomizeDishMultiple;
