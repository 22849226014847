import React from "react";
import { BiPencil } from "react-icons/bi";
import { BsDashLg, BsFillTrash3Fill, BsPlusLg } from "react-icons/bs";
import { useSelector } from "react-redux";
import CartItemImage from "./CartItemImage";
import cx from "classnames";

const CartItem = ({
  cartItem,
  setConfirmData,
  deleteCart,
  placeOrder,
  decrementCounter,
  incrementCounter,
  supaProducts,
}) => {
  const cartData = useSelector((state) => state.cart.cart);

  const cartItemData = cartData.filter(
    (item) => cartItem?.item?.item_no === item.id
  );
  const cartItemDataQty = cartItemData?.reduce(
    (total, record) => (total += record?.qty),
    0
  );
  const isMainSoldOut = cartItem?.is_main_soldout;
  const isAddOnSoldOut = cartItem.addOnValues?.find(
    (record) => record?.is_addon_soldout
  );

  return (
    <div className="bg-white">
      <div className="d-flex justify-content-between ps-4 pb-2 pe-3 pt-3">
        <div className="d-grid text-start">
          <label
            className={cx("cart-item-text mb-2 text-uppercase", {
              "text-danger": isMainSoldOut,
            })}>
            {cartItem.item.item_desc}
          </label>
          {cartItem?.item?.promo_name && (
            <span className="promo-name">
              Promotion: {cartItem?.item?.promo_name}
            </span>
          )}
          {cartItem?.item?.reward_name && (
            <span className="promo-name">
              Redeemed: {cartItem?.item?.reward_name}
            </span>
          )}
          {cartItem.addOnValues &&
            cartItem.addOnValues.length > 0 &&
            cartItem.addOnValues.map((custom, i) => (
              <>
                <label
                  key={i}
                  className={cx("modifier-text mb-1 text-uppercase", {
                    "text-danger": isMainSoldOut || custom?.is_addon_soldout,
                  })}>
                  {custom.modifier_name}: {custom.citem_name}{" "}
                  {custom?.modifier_qty > 1 && `x${custom?.modifier_qty}`}
                </label>
              </>
            ))}

          <label className="cart-item-price mb-1 mt-2">
            ${Number(cartItem.price * cartItem.qty).toFixed(2)}
          </label>
        </div>
        <div>
          {Object.keys(supaProducts).length > 0 && (
            <CartItemImage data={cartItem?.item} supaProducts={supaProducts} />
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between ps-4 pb-2 pe-3 align-items-center">
        <div className="d-flex">
          <BsFillTrash3Fill
            className="material-icons me-2"
            onClick={() =>
              setConfirmData({
                open: true,
                title: "Remove item",
                description: "Do you want to remove this item?",
                onConfirm: () => {
                  deleteCart(cartItem.uuid, cartItem);
                  setConfirmData({});
                },
              })
            }
          />

          {!cartItem?.item?.reward_name &&
            (cartItem.item.itemmaster_menutype_grpdtls === "" ? (
              <div className="d-flex">
                <label className="public-sans-font fw-normal font-16 black-text line-height-17 mb-1 mt-2 edit-btn-disable">
                  <BiPencil className="font-18 pe-1" />
                  Edit
                </label>
              </div>
            ) : (
              <label
                className="mt-1 edit-btn d-flex justify-content-center text-uppercase"
                onClick={() => placeOrder(cartItem.uuid)}>
                <BiPencil className="font-18 pe-1" />
              </label>
            ))}
        </div>
        {!cartItem?.item?.isFree ? (
          <div className="mb-1 mt-2">
            <BsDashLg
              className="qty-class me-3"
              onClick={() =>
                cartItem.qty === 1
                  ? setConfirmData({
                      open: true,
                      title: "Remove item",
                      description: "Do you want to remove this item?",
                      onConfirm: () => {
                        decrementCounter(cartItem.uuid, cartItem);
                        setConfirmData({});
                      },
                    })
                  : decrementCounter(cartItem.uuid, cartItem)
              }
            />
            <label className="cart-item-qty">{cartItem.qty}</label>
            <BsPlusLg
              className="qty-class ms-3"
              onClick={() => {
                if (
                  !(
                    cartItemDataQty >= cartItem.item.bal_qty &&
                    cartItem.item.bal_qty !== 0
                  )
                ) {
                  incrementCounter(cartItem.uuid, cartItem);
                }
              }}
            />
          </div>
        ) : (
          <label
            className="cart-item-qty"
            style={{
              marginRight: 32,
            }}>
            {cartItem.qty}
          </label>
        )}
      </div>
      {(isMainSoldOut || isAddOnSoldOut) && (
        <div className="d-flex ps-4 pe-3">
          <span
            className="text-danger"
            style={{
              textAlign: "left",
              fontWeight: 500,
              fontSize: 14,
              textTransform: "uppercase",
            }}>
            This item / option is out stock. Please edit your choice or remove
            this item from cart
          </span>
        </div>
      )}
    </div>
  );
};

export default CartItem;
