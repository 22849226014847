export const formatCents = (cents) => {
  const formattedCent = new Intl.NumberFormat("en-SG", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "SGD",
  }).format(cents);

  return formattedCent;
};

export const roundNumber = (number, decimalPlaces = 2) => {
  const factor = 10 ** decimalPlaces;

  return Math.round(Number(number) * factor) / factor;
};
