import React from "react";
import Skeleton from "react-loading-skeleton";

const MainCategoryItemPlaceholder = () => {
  return (
    <div
      role="status"
      style={{
        padding: 8,
        border: "solid 1px",
        borderColor: "rgb(156, 156, 156)",
        borderRadius: 8,
      }}>
      <Skeleton height={150} />
      <Skeleton height={12} />
      <Skeleton height={8} />
    </div>
  );
};

export default MainCategoryItemPlaceholder;
