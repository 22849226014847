import { STORAGEKEY } from "../../constants";
import { apiCall } from "../../lib/supabase";

export const sendPosLog = async (salesNo, payload) => {
  const { data } = await apiCall
    .from("brand")
    .select("id")
    .eq("name", process.env.REACT_APP_MODE);

  await apiCall.from("pos_log").insert({
    brand: data?.[0]?.id,
    payload,
    store_name: localStorage.getItem(STORAGEKEY.STORE_NAME),
    sales_no: salesNo,
  });

  return {
    result: "Success upload to pos log",
  };
};
