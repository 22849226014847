// const pavilion = {
//   siteName: "pavilion",
//   isPaymentShow: true,
//   isLoginShow: false,
//   isDineOption: true,
//   EmptyImage: "empty_cart",
//   star: "star",
//   chef: "chef",
//   empty: "empty",
//   clock: "clock",
//   payment: "payment",
//   apple: "apple",
//   google: "google",
//   flower: "flower",
//   search: "search",
//   qr_code_error: "qr_code_error",
//   order_mode: "PayFirst",
//   qr_type: "Static",
//   register_no: "POS01",
//   home_banner_enable: false,
//   diningOption: "DineInAndTakeAway", // DineInOnly, TakeAwayOnly, DineInAndTakeAway
// };

// const gochiso = {
//   siteName: "Gochiso",
//   isPaymentShow: false,
//   isLoginShow: false,
//   isDineOption: true,
//   EmptyImage: "empty_cart",
//   star: "star",
//   chef: "chef",
//   empty: "empty",
//   clock: "clock",
//   payment: "payment",
//   apple: "apple",
//   google: "google",
//   flower: "flower",
//   search: "search",
//   qr_code_error: "qr_code_error",
//   order_mode: "PayAtCounter",
//   qr_type: "Dynamic",
//   register_no: "POS01",
//   home_banner_enable: false,
//   diningOption: "DineInOnly", // DineInOnly, TakeAwayOnly, DineInAndTakeAway
// };
// const butakizoku = {
//   siteName: "butakizoku",
//   isPaymentShow: false,
//   isLoginShow: false,
//   isDineOption: false,
//   EmptyImage: "empty_cart",
//   star: "star",
//   chef: "chef",
//   empty: "empty",
//   clock: "clock",
//   payment: "payment",
//   apple: "apple",
//   google: "google",
//   flower: "flower",
//   search: "search",
//   qr_code_error: "qr_code_error",
//   order_mode: "PayAtCounter",
//   qr_type: "Dynamic",
//   register_no: "POS01",
//   home_banner_enable: false,
//   diningOption: "DineInOnly", // DineInOnly, TakeAwayOnly, DineInAndTakeAway
// };

const siteDetails = {
  astons: {
    siteName: "astons",
    paymentMode: ["Counter", "Online"],
    isLoginShow: false,
    isDineOption: true,
    EmptyImage: "empty_cart",
    star: "star",
    chef: "chef",
    empty: "empty",
    clock: "clock",
    payment: "payment",
    apple: "apple",
    google: "google",
    flower: "flower",
    search: "search",
    qr_code_error: "qr_code_error",
    order_mode: "PayFirst",
    qr_type: "Static",
    register_no: "POS01",
    home_banner_enable: false,
    diningOption: "DineInAndTakeAway",
    hasServiceCharge: false,
    gstInclusive: false,
    enableServiceCharge: false,
    enableGST: false,
    enableLogin: false,
    enableRedemption: false,
    enableRemarks: true,
  },
  astonsprime: {
    siteName: "astonsprime",
    paymentMode: ["Online"],
    isLoginShow: false,
    isDineOption: true,
    EmptyImage: "empty_cart",
    star: "star",
    chef: "chef",
    empty: "empty",
    clock: "clock",
    payment: "payment",
    apple: "apple",
    google: "google",
    flower: "flower",
    search: "search",
    qr_code_error: "qr_code_error",
    order_mode: "PayFirst",
    qr_type: "Static",
    register_no: "POS01",
    home_banner_enable: false,
    diningOption: "DineInOnly",
    hasServiceCharge: false,
    gstInclusive: true,
    enableServiceCharge: false,
    enableGST: true,
    enableLogin: true,
    enableRedemption: true,
    enableRemarks: false,
  },
  pavilion: {
    siteName: "pavilion",
    isPaymentShow: true,
    isLoginShow: false,
    isDineOption: true,
    EmptyImage: "empty_cart",
    star: "star",
    chef: "chef",
    empty: "empty",
    clock: "clock",
    payment: "payment",
    apple: "apple",
    google: "google",
    flower: "flower",
    search: "search",
    qr_code_error: "qr_code_error",
    order_mode: "PayFirst",
    qr_type: "Static",
    register_no: "POS01",
    home_banner_enable: false,
    diningOption: "DineInAndTakeAway",
    hasServiceCharge: true,
  },
  gochiso: {
    siteName: "Gochiso",
    isPaymentShow: false,
    isLoginShow: false,
    isDineOption: true,
    EmptyImage: "empty_cart",
    star: "star",
    chef: "chef",
    empty: "empty",
    clock: "clock",
    payment: "payment",
    apple: "apple",
    google: "google",
    flower: "flower",
    search: "search",
    qr_code_error: "qr_code_error",
    order_mode: "PayAtCounter",
    qr_type: "Dynamic",
    register_no: "POS01",
    home_banner_enable: false,
    diningOption: "DineInOnly",
    hasServiceCharge: true,
  },
  butakizoku: {
    siteName: "butakizoku",
    isPaymentShow: false,
    isLoginShow: false,
    isDineOption: false,
    EmptyImage: "empty_cart",
    star: "star",
    chef: "chef",
    empty: "empty",
    clock: "clock",
    payment: "payment",
    apple: "apple",
    google: "google",
    flower: "flower",
    search: "search",
    qr_code_error: "qr_code_error",
    order_mode: "PayAtCounter",
    qr_type: "Dynamic",
    register_no: "POS01",
    home_banner_enable: false,
    diningOption: "DineInOnly",
    hasServiceCharge: true,
  },
  whitetiffin: {
    siteName: "whitetiffin",
    paymentMode: ["Counter", "Online", "QlubQR"],
    isLoginShow: false,
    isDineOption: true,
    EmptyImage: "empty_cart",
    star: "star",
    chef: "chef",
    empty: "empty",
    clock: "clock",
    payment: "payment",
    apple: "apple",
    google: "google",
    flower: "flower",
    search: "search",
    qr_code_error: "qr_code_error",
    order_mode: "PayFirst",
    qr_type: "Static",
    register_no: "POS01",
    home_banner_enable: false,
    diningOption: "DineInAndTakeAway",
    hasServiceCharge: false,
  },
  mannabistro: {
    siteName: "mannabistro",
    isPaymentShow: false,
    isLoginShow: false,
    isDineOption: true,
    EmptyImage: "empty_cart",
    star: "star",
    chef: "chef",
    empty: "empty",
    clock: "clock",
    payment: "payment",
    apple: "apple",
    google: "google",
    flower: "flower",
    search: "search",
    qr_code_error: "qr_code_error",
    order_mode: "PayFirst",
    qr_type: "Static",
    register_no: "POS01",
    home_banner_enable: false,
    diningOption: "DineInAndTakeAway",
    hasServiceCharge: false,
  },
  secretrecipe: {
    siteName: "secretrecipe",
    paymentMode: ["Counter", "Online"],
    isLoginShow: false,
    isDineOption: true,
    EmptyImage: "empty_cart",
    star: "star",
    chef: "chef",
    empty: "empty",
    clock: "clock",
    payment: "payment",
    apple: "apple",
    google: "google",
    flower: "flower",
    search: "search",
    qr_code_error: "qr_code_error",
    order_mode: "PayFirst",
    qr_type: "Static",
    register_no: "POS01",
    home_banner_enable: false,
    diningOption: "DineInAndTakeAway",
    hasServiceCharge: true,
    gstInclusive: false,
    enableServiceCharge: true,
    enableGST: true,
    enableLogin: false,
    enableRedemption: false,
    enableRemarks: true,
  },
};
const config = siteDetails[process.env.REACT_APP_MODE];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...config,
};
