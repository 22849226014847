import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import Header from "../../component/header";
import HeaderMenu from "../../component/headermenu";
import CarouselBanner from "./CarouselBanner";
import Scrollspy from "react-scrollspy-ez";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineShoppingCart } from "react-icons/md";
import { SlBag } from "react-icons/sl";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { Button, Spinner, Card } from "react-bootstrap";
// import TimeKeeper from "react-timekeeper";
// import { CustomDialog } from "react-st-modal";
import {
  storeMenuButton,
  storeMenuItems,
  storeStockList,
} from "../../services/tableQRServices";
import _, { includes, isEmpty, sortBy } from "lodash";
// import MenuItems from "../../component/MenuItems";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/cartSlice";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProducts } from "../../services/supabase/product";
import { getCategories } from "../../services/supabase/category";
import useBrandConfig from "../../hooks/useBrandConfig";
import {
  setPosFullItemList,
  setPosMenuButton,
  setPosStoreStockList,
} from "../../redux/posSlice";
import { STORAGEKEY } from "../../constants";
import useSessionTimeout from "../../hooks/useSessionTimeout";
import WelcomeBanner from "../../component/WelcomBanner/WelcomeBanner";
import useCategory from "../../hooks/useCategory";
import UpsellingModal from "../../component/Upselling/UpsellingModal";
import getUpsellingItems from "../../utils/getUpsellingitems";
import MainCategoryItemPlaceholder from "../../component/MainCategoryItemPlaceholder/MainCategoryItemPlaceholder";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const navigationData = location.state;

  const [isBottomOpen, setIsBottomOpen] = useState(false);
  const [enableOverlay, setEnableOverlay] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);
  // const [time, setTime] = useState("12:34pm");
  const [menuCategories, setMenuCategories] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [searchedMenuItems, setSearchedMenuItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [allCategories, setAllCategories] = useState([]);

  const { config } = useBrandConfig();
  const sessionTimeout = useSessionTimeout();
  const { checkingEmptyCategory } = useCategory();
  const [showUpsellingModal, setShowUpsellingModal] = useState(false);
  const [upsellingItems, setUpsellingItems] = useState([]);

  // get the number of items in the cart
  const cartItems = useSelector((state) => state.cart.cart);
  // get items from the cart and then read the qty of each item
  // sum the items to get cart items count each time an item is added to the cart
  const cartItemsCount = cartItems.reduce((acc, item) => acc + item.qty, 0);
  const { posStoreStockList, posMenuButton, posFullItemList } = useSelector(
    (state) => state.pos
  );

  const query = new URLSearchParams(window.location.search);

  // category image
  const fetchSupabaseCategories = async () => {
    setIsLoading(true);
    const resp = await getCategories(process.env.REACT_APP_MODE);

    if (
      !posMenuButton?.length ||
      !posMenuButton?.find((record) => record?.root_category_code === "MAIN")
        ?.category
    ) {
      navigate("/qr-not-valid");

      return;
    }

    const filterCategory = resp.data?.filter(
      (record) => record?.is_hidden !== true && record?.is_hidden !== "Y"
    );

    let formatAllCategories = [];

    posMenuButton
      ?.find((record) => record?.root_category_code === "MAIN")
      ?.category?.filter(
        (category) =>
          category.start_time <= moment().format("HH:mm:ss") &&
          category.end_time > moment().format("HH:mm:ss")
      )
      ?.forEach((record) => {
        const findCategory = filterCategory?.find(
          (cat) => cat?.name === record?.category_code
        );

        if (findCategory) {
          const findCategory = filterCategory?.find(
            (cat) => cat?.name === record?.category_code
          );
          formatAllCategories.push(findCategory);
        }
      });

    formatAllCategories = formatAllCategories.sort(
      (a, b) => a?.sequence - b?.sequence
    );

    const formatSubCategory = [];

    formatAllCategories
      ?.map((record) => record?.name)
      .forEach((cat) => {
        const findSubCategory = posMenuButton?.find(
          (record) => record?.root_category_code === cat
        );

        if (
          findSubCategory &&
          !findSubCategory?.category &&
          findSubCategory?.items
        ) {
          const findCategory = filterCategory?.find(
            (cat) => cat?.name === findSubCategory?.root_category_code
          );

          if (findCategory) {
            formatSubCategory.push(findCategory);
          }
        }
      });

    const formatCategory = await checkingEmptyCategory(
      filterCategory,
      formatSubCategory?.map((record) => record?.name)
    );

    const filterAllCategories = formatAllCategories?.filter(
      (record) =>
        !formatSubCategory
          ?.map((record) => record?.name)
          ?.includes(record?.name)
    );
    const filterFormatCategories = [];

    formatCategory
      ?.filter((record) => record)
      ?.forEach((record) => {
        const findCategory = filterCategory?.find(
          (cat) => cat?.name === record
        );

        if (findCategory) {
          filterFormatCategories.push(findCategory);
        }
      });

    setAllCategories(
      [...filterFormatCategories, ...filterAllCategories].sort(
        (a, b) => a?.sequence - b?.sequence
      )
    );
  };

  useEffect(() => {
    if (
      posStoreStockList &&
      JSON.parse(posStoreStockList || "[]")?.length &&
      posMenuButton?.length > 0 &&
      posFullItemList?.length > 0
    ) {
      fetchSupabaseCategories();
    }
  }, [
    allCategories?.length,
    posStoreStockList,
    posMenuButton,
    posFullItemList,
  ]);

  // function to get the category image based on name
  const getCategoryImage = (categoryName) => {
    const category = allCategories.find(
      (category) => category.name === categoryName
    );
    return category?.image_url;
  };

  // end category image

  useEffect(() => {
    setIsBottomOpen(false);

    const diningMode = localStorage.getItem(STORAGEKEY.DINING_MODE);

    if (diningMode !== "takeaway") {
      if (config?.serviceMode === "DineInOnly") {
        // setIsBottomOpen(false);
        localStorage.setItem(STORAGEKEY.DINING_MODE, "dinein");
      } else if (config?.serviceMode === "TakeAwayOnly") {
        // setIsBottomOpen(false);
        localStorage.setItem(STORAGEKEY.DINING_MODE, "takeaway");
      } else {
        localStorage.setItem(STORAGEKEY.DINING_MODE, "dinein");
      }

      if (config?.isDineOption) {
        let getStorageOption = localStorage.getItem(STORAGEKEY.DINING_MODE);
        if (getStorageOption === "" || getStorageOption === null) {
          setIsBottomOpen(true);
        }
      }
    }

    return () => {
      // Clear the listener on component destroyed
      if (menuRef && menuRef.current && menuRef.current.removeEventListener) {
        menuRef.current.removeEventListener("scroll", calculateScrollFn);
      }
    };
  }, [config]);

  const subTotal = useSelector((state) => state.cart.total);
  const dispatcher = useDispatch();

  const itemsListAndCategory = () => {
    const resultParse = JSON.parse(posStoreStockList || "[]");

    const newResultParse = resultParse?.filter(
      (record) => record?.is_emenu_disable !== "Y"
    );

    let resultItemsParsed = newResultParse.filter(
      (category) => category.avl_type === "I"
    );
    const categoryNames = allCategories.map((item) => item.name);

    menuButtonFunction(
      categoryNames,
      resultItemsParsed,
      newResultParse,
      resultParse
    );
  };

  const menuButtonFunction = (
    categoryCodes,
    resultItemsParsed,
    resultParse,
    originalResultParse
  ) => {
    let newCategoryCodes = [];

    categoryCodes?.forEach((record) => {
      const findCategory = originalResultParse?.find(
        (cat) => cat?.item_category === record
      );

      if (
        findCategory &&
        findCategory?.is_emenu_disable !== "Y" &&
        findCategory?.is_soldout !== "Y"
      ) {
        newCategoryCodes.push(record);
      } else if (!findCategory) {
        newCategoryCodes.push(record);
      }
    });

    const categoryMenuButton = posMenuButton;

    let categoryMainMenu = categoryMenuButton.filter(
      (menuItem) => menuItem.category && menuItem.category.length > 0
      // (menuItem) => menuItem.root_category_code === "MAIN"
      // filter menuItem.items length > 0
      // (menuItem) => menuItem.items.length > 0
    );
    let categoryMainMenuButton = [];
    for (const catItems of categoryMainMenu) {
      categoryMainMenuButton.push(...catItems.category);
    }
    if (categoryMainMenuButton && categoryMainMenuButton.length > 0) {
      let categoriesIndex = categoryMainMenuButton;
      // categoryMainMenuButton = categoryMainMenuButton[0];
      // let categoriesIndex = categoryMainMenuButton.category;
      // ? Sort on sequences and filter on start and end time
      categoriesIndex = categoriesIndex
        .sort((a, b) => a.course_seq - b.course_seq)
        .filter(
          (category) =>
            category.start_time <= moment().format("HH:mm:ss") &&
            category.end_time > moment().format("HH:mm:ss")
        );

      const categoregoryFromApi = categoryMenuButton.map((category) => {
        return category.root_category_code;
      });
      newCategoryCodes = _.intersection(newCategoryCodes, categoregoryFromApi);
      // for (const category of categoriesIndex) {
      //   categoryCodes.push(category.category_code);
      // }
      let menuItemsArray = [];
      for (const catItem of categoryMenuButton) {
        if (catItem.items && catItem.items.length > 0) {
          menuItemsArray.push(...catItem.items);
        }
      }
      // ? Intersect items from stock and button api
      let intersectCategory = _.intersectionBy(
        resultItemsParsed,
        menuItemsArray,
        (obj) => obj.item_category || obj.item_no
      );
      // ? Intersect items from with intersected items and button api items
      intersectCategory = Object.values(
        _.merge(
          _.keyBy(intersectCategory, "item_category"),
          _.keyBy(menuItemsArray, "item_no")
        )
      ).filter(
        (item) =>
          item.is_emenu_disable === "N" &&
          item.start_time <= moment().format("HH:mm:ss") &&
          item.end_time > moment().format("HH:mm:ss")
      );
      setMenuCategories(newCategoryCodes);
      menuItemsFunction(intersectCategory, newCategoryCodes, resultParse);
    }
  };

  const menuItemsFunction = (intersectCategory, categoryCodes, resultParse) => {
    const result = posFullItemList;

    const filterItemMaster = result.filter(
      (item) =>
        item.itemmaster_menutypedtls && item.itemmaster_menutypedtls.length > 0
    );

    const stockedCheckedItem = [];
    if (filterItemMaster && filterItemMaster.length > 0) {
      for (const itemMaster of filterItemMaster) {
        let itemmaster_menutypedtls = [];
        for (const typeDtls of itemMaster.itemmaster_menutypedtls) {
          const modifierQty = resultParse.filter(
            (itemCategory) => itemCategory.item_no === typeDtls.citem_no
          );

          if (modifierQty && modifierQty.length > 0) {
            itemmaster_menutypedtls.push({
              ...typeDtls,
              is_soldout: modifierQty[0].is_soldout,
              is_emenu_disable: modifierQty[0].is_emenu_disable,
              is_avl_limit_check: modifierQty[0].is_avl_limit_check,
              bal_qty: modifierQty[0].bal_qty,
            });
          } else {
            itemmaster_menutypedtls.push({ ...typeDtls });
          }
        }

        stockedCheckedItem.push({ ...itemMaster, itemmaster_menutypedtls });
      }
    }
    const filterNoItemMaster = result.filter(
      (item) =>
        !item.itemmaster_menutypedtls ||
        item.itemmaster_menutypedtls.length <= 0
    );

    let intersectItems = _.intersectionBy(
      intersectCategory,
      [...filterNoItemMaster, ...stockedCheckedItem],
      (obj) => obj.item_category || obj.item_no
    ).filter((item) => item.is_active);

    intersectItems = Object.values(
      _.merge(
        _.keyBy(intersectCategory, "item_category"),
        _.keyBy([...filterNoItemMaster, ...stockedCheckedItem], "item_no")
      )
    )
      .filter(
        (item) => item.is_active && categoryCodes.includes(item.category_code)
      )
      .filter(
        (item) => item.is_emenu_disable === "N" && item.is_soldout === "N"
      );

    const sortAlphabetically = intersectItems.sort((a, b) =>
      a.item_desc.localeCompare(b.item_desc)
    );
    const sortByCategory = sortAlphabetically
      .sort(
        (a, b) =>
          categoryCodes.indexOf(a.category_code) -
          categoryCodes.indexOf(b.category_code)
      )
      .filter(
        (item) =>
          item.start_time <= moment().format("HH:mm:ss") &&
          item.end_time > moment().format("HH:mm:ss")
      );

    setMenuItems(sortByCategory);
    setIsLoading(false);
  };

  useEffect(() => {
    if (
      allCategories?.length > 0 &&
      posStoreStockList &&
      JSON.parse(posStoreStockList || "[]")?.length &&
      posMenuButton?.length > 0 &&
      posFullItemList?.length > 0
    ) {
      itemsListAndCategory();
    }
  }, [
    allCategories,
    posFullItemList,
    posMenuButton?.length,
    posStoreStockList,
  ]);

  let menuItem, menuDish;
  menuItem = menuCategories;
  menuDish = searchedMenuItems && showSearchBar ? searchedMenuItems : menuItems;
  const placeOrder = (dishDetail) => {
    if (dishDetail.itemmaster_menutype_grpdtls === "") {
      dispatcher(
        addToCart({
          id: dishDetail.item_no,
          addOnValues: [],
          item: dishDetail,
          qty: 1,
          price: dishDetail.selling_uom_dtls[0]?.price_dtls[0]?.dine_in_price,
        })
      );
      toast.success(`${dishDetail.item_name}  ADDED TO CART`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
      // navigate("/cart");
    } else {
      navigate("/customize-dish", {
        state: { item: dishDetail },
      });
    }
  };
  const toggleBottom = () => {
    if (
      localStorage.getItem("selectiDineOption") === "dinein" ||
      localStorage.getItem("selectiDineOption") === "takeaway"
    ) {
      setIsBottomOpen((prev) => !prev);
    }
  };

  const openDiningOption = () => {
    setIsBottomOpen(true);
  };

  const selectDining = (selectionOption) => {
    localStorage.setItem("selectiDineOption", selectionOption);
    // set current cart to dine in
    setIsBottomOpen(false);
  };
  const searchFunction = (values) => {
    if (values && values.length > 0) {
      setSearchedMenuItems(
        menuItems.filter(
          (item) =>
            item.item_name.includes(values) ||
            item.item_name.toLowerCase().includes(values.toLowerCase()) ||
            item.item_name.toUpperCase().includes(values.toUpperCase())
        )
      );
    } else {
      // itemsListAndCategory();
      setSearchedMenuItems(menuItems);
    }
  };
  const handleSearchClick = () => {
    setShowSearchBar(!showSearchBar);
  };

  const [initialized, setInitialized] = useState(false);
  const menuRef = useRef();

  let pendingAction;
  const calculateScrollFn = () => {
    if (pendingAction) {
      clearTimeout(pendingAction);
      pendingAction = false;
    }
    pendingAction = setTimeout(() => {
      const activeMenuLi = document.querySelector(".spyItemClass.active");
      if (!activeMenuLi) {
        return;
      }
      const menuContainer = activeMenuLi.parentNode;

      const elementRect = activeMenuLi.getBoundingClientRect();
      const containerRect = menuContainer.getBoundingClientRect();

      let scrollGap;
      if (elementRect.top < containerRect.top) {
        scrollGap = elementRect.top - containerRect.top;
      }
      if (elementRect.bottom > containerRect.bottom) {
        scrollGap = elementRect.bottom - containerRect.bottom;
      }
      if (scrollGap) {
        menuContainer.scrollBy(0, scrollGap);
      }
    }, 50);
  };

  function initScrollListener() {
    if (initialized) {
      return;
    }
    if (!menuRef || !menuRef.current) {
      return;
    }
    menuRef.current.removeEventListener("scroll", calculateScrollFn);
    menuRef.current.addEventListener("scroll", calculateScrollFn);
    setInitialized(true);
  }

  const handleCardclick = (category) => {
    navigate("/category", {
      state: { category: category },
    });
  };

  function insertLineBreak(category) {
    if (category.includes("(")) {
      console.log("breaking category", category);
      return category.replace(" (", "\n(");
    } else {
      return category;
    }
  }

  const getCategoryShortName = (categoryName) => {
    const category = allCategories.find(
      (category) => category.name === categoryName
    );
    return category?.name;
  };

  const handleGetStockList = async () => {
    setIsLoading(true);

    const res = await storeStockList();

    const result = res?.data?.data[0]?.output;

    if (!JSON.parse(result || "[]")?.length) {
      navigate("/qr-not-valid");

      return;
    }

    dispatch(setPosStoreStockList(result));
  };

  const handleGetMenuButton = async () => {
    const res = await storeMenuButton();

    const categoryMenuButton = res?.data?.data[0]?.output;

    if (!categoryMenuButton?.length) {
      navigate("/qr-not-valid");

      return;
    }

    dispatch(setPosMenuButton(categoryMenuButton));
  };

  const handleGetMenuItems = async () => {
    const res = await storeMenuItems();

    const result = res?.data?.data[0]?.output;

    if (!result?.length) {
      navigate("/qr-not-valid");

      return;
    }

    dispatch(setPosFullItemList(result));
  };

  // useLayoutEffect(() => {
  //   if (
  //     !posStoreStockList &&
  //     !posMenuButton?.length &&
  //     !posFullItemList?.length
  //   ) {
  //     handleGetStockList();
  //     handleGetMenuButton();
  //     handleGetMenuItems();
  //   }
  // }, [posFullItemList, posMenuButton, posStoreStockList]);

  const clearState = () => {
    navigate(location.pathname, { replace: true });
  };

  const checkUpselling = async (categoryCode, itemCategory) => {
    const { hasUpsellingItems, items: upsellItems } = await getUpsellingItems(
      categoryCode,
      itemCategory
    );
    if (hasUpsellingItems) {
      setUpsellingItems(upsellItems);
      setShowUpsellingModal(true);
    }
  };

  useEffect(() => {
    if (!isEmpty(navigationData)) {
      console.log("check", navigationData.item);
      const {
        item: { category_code, item_category },
      } = navigationData;
      checkUpselling(category_code, item_category);
    }
  }, [navigationData]);

  const handleValidateStoreName = () => {
    const storeName = localStorage.getItem(STORAGEKEY.STORE_NAME);
    const tableNo = localStorage.getItem(STORAGEKEY.TABLE_NO);

    if (!storeName || !tableNo || query.get("storename") !== storeName) {
      navigate("/qr-not-valid");
    }
  };

  useEffect(() => {
    handleValidateStoreName();
  }, []);

  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header
            title="home"
            showSearchBar={showSearchBar}
            handleSearchClick={handleSearchClick}
            searchFunction={searchFunction}
            openDiningOption={openDiningOption}
          />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div
            className="main-div home-div position-absolute w-100"
            ref={menuRef}>
            <HeaderMenu menuItem={menuItem} />

            {config?.home_banner_enabled && <CarouselBanner />}
            <div
              className="top-border d-flex flex-column position-relative"
              style={{
                paddingTop: 180,
                minHeight: "100vh",
              }}>
              {!initialized && initScrollListener()}
              {isLoading ? (
                <div className="ps-2 pe-2 w-100 mb-2 category-cards-container">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <Fragment key={index}>
                      <MainCategoryItemPlaceholder />
                    </Fragment>
                  ))}
                </div>
              ) : (
                <>
                  {menuItem && menuItem.length > 0 ? (
                    <>
                      <div className="d-flex flex-wrap align-items-center ps-2 pe-2 w-100 mb-2">
                        {menuItem.map(
                          (category, index) =>
                            getCategoryShortName(category) && (
                              <>
                                <div
                                  key={index}
                                  className="category-cards col-6 col-sm-6 col-md-4 col-lg-3">
                                  <Card
                                    className="category-card"
                                    onClick={() => handleCardclick(category)}>
                                    {/* in the src of the image, we call getCategoryImage to get the image of the category, else load the default image */}
                                    <Card.Img
                                      variant="top"
                                      // src={` /assets/${process.env.REACT_APP_MODE}/logo/logo2.png`}
                                      src={
                                        getCategoryImage(category) ||
                                        `/assets/${process.env.REACT_APP_MODE}/logo/logo2.png`
                                      }
                                      className={`category-card-img ${
                                        !getCategoryImage(category)
                                          ? "image-contain"
                                          : ""
                                      }`}
                                    />
                                    <Card.Body className="category-card-body">
                                      <Card.Title className="category-card-title break-parenthesis">
                                        {insertLineBreak(category)}
                                      </Card.Title>
                                    </Card.Body>
                                    <div className="category-card-footer d-flex justify-content-end align-items-center me-2">
                                      <span>View More &gt;&gt;</span>
                                    </div>
                                  </Card>
                                </div>
                              </>
                            )
                        )}
                      </div>
                    </>
                  ) : (
                    <div>
                      <img
                        src={`/assets/commonImages/${config?.search}.png`}
                        alt=""
                        className="mt-5"
                      />
                      <div className="d-grid">
                        <label className="no-result-text black-text">
                          No Result
                        </label>
                        <label className="no-result-desc text-black">
                          Sorry we couldn't find any result, try another
                          keyword.
                        </label>
                      </div>
                    </div>
                  )}

                  {/* <div className="home-bottom-div">
                    <Link to="/cart">
                      <button className="viewcart-btn w-60 fill-btn mb-2 me-4 ms-4 pulsing-button">
                        <>
                          <div className="d-flex justify-content-space-evenly pt-1 flex-wrap">
                            <div className="col">
                              <MdOutlineShoppingCart className="shopping-cart-icon" />
                            </div>
                            <div className="col fw-bold text-start">
                              Items: {cartItemsCount}
                            </div>
                            <div className="cart-price col">
                              $ {Number(subTotal).toFixed(2)}
                            </div>
                          </div>
                        </>
                      </button>
                    </Link>
                  </div> */}
                </>
              )}
            </div>

            <Drawer
              open={isBottomOpen}
              onClose={toggleBottom}
              direction="bottom"
              size={300}
              enableOverlay={enableOverlay}>
              <div>
                <label className="d-flex pt-3 ps-3 select-option-text text-uppercase">
                  Select your dining experience
                </label>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <span className="material-icons pt-3"> restaurant </span>
                    </div>
                    <label className="option-text mt-3 ms-3 text-uppercase">
                      Dine in
                    </label>
                    <Button
                      className="fill-btn cart-btn dining-btn mt-2 position-absolute end-0 me-4 mt-2 text-uppercase"
                      type="submit"
                      onClick={() => selectDining("dinein")}>
                      Select
                    </Button>
                  </div>
                </div>
                <div className="option-div me-3 ms-3 mt-4 pt-3 pb-3">
                  <div className="dining-select-div">
                    <div className="option-icon">
                      <SlBag className="material-icons mt-3 font-18" />
                    </div>
                    <label className="option-text mt-3 ms-3 text-uppercase">
                      Takeaway
                    </label>
                    <Button
                      className="fill-btn cart-btn dining-btn mt-2 position-absolute end-0 me-4 mt-2 text-uppercase"
                      type="submit"
                      onClick={async () => {
                        localStorage.setItem("selectiDineOption", "takeaway");
                        setIsBottomOpen(false);
                      }}>
                      Select
                    </Button>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>
      <WelcomeBanner />
      {showUpsellingModal ? (
        <UpsellingModal
          show={showUpsellingModal}
          handleClose={() => setShowUpsellingModal(false)}
          recommendationItems={upsellingItems}
          clearUpsellingState={clearState}
        />
      ) : null}
    </>
  );
}
export default Home;
