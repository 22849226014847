import React, { useState } from "react";
import Header from "../../component/header";
import { IoCheckmarkCircle } from "react-icons/io5";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { STORAGEKEY } from "../../constants";
import { addToCart } from "../../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";

const Orderdetails = () => {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const dispatcher = useDispatch();
  const navigate = useNavigate();
  const invoiceNo = useSelector((state) => state.cart.sales_no);
  const handleReorder = (item) => {
    setLoading(true);
    // state.orderData.items.forEach((item) => {

    dispatcher(
      addToCart({
        id: item.item.item_no,
        addOnValues: item.addOnValues,
        item: item.item,
        qty: item.qty,
        price: item.price,
      })
    );
    // });
    navigate("/cart");
    setLoading(false);
  };
  return (
    <>
      <div className="App">
        <div className="qr-before-body w-100">
          <Header title="Order Detail" />
          <div
            className="inner-div main-div position-absolute w-100"
            style={{ maxHeight: "90vh" }}>
            <div className="bg-white">
              <div className="d-flex  justify-content-between pt-3 ps-4 pb-3 pe-3 div-border text-start">
                <div className="d-grid">
                  <label className="invoice-text mb-1">{invoiceNo}</label>
                  <label className="order-date-text">
                    {moment(state.orderData.time).format("DD MMM YYYY HH:mm A")}
                  </label>
                </div>
                <div className="mt-2 me-2">
                  <IoCheckmarkCircle className="check-icon-color" />
                </div>
              </div>
              <div className="d-flex  justify-content-between pt-2 ps-4 pb-2 pe-3 text-start">
                <div className="d-grid">
                  <label className="invoice-text">
                    {localStorage.getItem("selectiDineOption") === "dinein"
                      ? "Dine In"
                      : "Takeaway"}
                  </label>
                  <label className="order-date-text">
                    Table-{localStorage.getItem(STORAGEKEY.TABLE_NO)}
                  </label>
                </div>
                <div className="option-icon">
                  <span className="material-icons mt-3 ms-2 ps-2">
                    {" "}
                    restaurant{" "}
                  </span>
                </div>
              </div>
            </div>
            <label className="ps-4 mt-3 mb-3 order-summary-text d-flex">
              Order Summary
            </label>
            {state.orderData.items &&
              state.orderData.items.length > 0 &&
              state.orderData.items.map((items) => (
                <div className="bg-white ps-4 pb-3 pe-3 div-border">
                  <div className="d-flex pt-3">
                    <div className="leftbox me-2">
                      <label className="order-cart-qty material-icons">
                        {items.qty}
                      </label>
                    </div>
                    <div className="middlebox d-grid text-start">
                      <label className="order-details-desc mb-2">
                        {items.item.item_desc}
                      </label>
                      {items.addOnValues &&
                        items.addOnValues.length > 0 &&
                        items.addOnValues.map((addOn) => (
                          <>
                            <label className="order-details-modifier mb-1">
                              {addOn.modifier_name} : {addOn.citem_name}
                            </label>
                          </>
                        ))}
                      <label className="order-details-price mb-1">
                        $ {Number(items.price).toFixed(2)}{" "}
                      </label>
                    </div>
                    <div className="rightbox text-end ms-2">
                      <Button
                        className="border-btn reorder-btn"
                        onClick={() => handleReorder(items)}
                        type="button"
                        disabled={loading}>
                        Reorder
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            <label className="ps-4 mt-3 mb-3 payment-summart-text d-flex">
              Payment Summary
            </label>
            <div className="bg-white d-flex justify-content-between pt-4 pe-4 ps-4 pb-2 mb-4">
              <table className="w-100 text-start">
                <tr>
                  <td className="order-detail-subtotal pb-2">Subtotal</td>
                  <td className="order-detail-subtotal pb-2 text-end">
                    $ {Number(state.orderData.subTotal).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="order-detail-subtotal pb-2">
                    Service Charge (10%)
                  </td>
                  <td className="order-detail-subtotal pb-2 text-end">
                    $ {Number(state.orderData.serviceTax).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="order-detail-subtotal">GST 8% (Inclusive)</td>
                  <td className="order-detail-subtotal text-end">
                    $ {Number(state.orderData.GSTTaxValue).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <hr className="dashed-border" />
                  </td>
                </tr>
                <tr>
                  <td className="order-details-total">Total</td>
                  <td className="order-details-total text-end">
                    ${" "}
                    {(
                      Number(state.orderData.subTotal) +
                      Number(state.orderData.serviceTax) +
                      Number(state.orderData.GSTTaxValue)
                    ).toFixed(2)}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Orderdetails;
